import { config, encryptAndDecryptXorString } from "JS/Config";
import { appConstants } from "./Contants";
import moment from "moment";

export const getPlaylistMigrated = () =>
    localStorage.getItem(appConstants.localStorage.playlistMigrated);

export const setPlaylistMigrated = () =>
    localStorage.setItem(appConstants.localStorage.playlistMigrated, "true");

export const getLoggedInUserMigrated = () =>
    localStorage.getItem(appConstants.localStorage.loggedInUserMigrated);

export const getIsRedirectedToAmmwayConsentForm = () =>
    localStorage.getItem(
        appConstants.localStorage.isRedirectedToAmmwayConsentForm,
    );

export const getIsLtdConsentFormOpened = () =>
    localStorage.getItem(appConstants.localStorage.isLtdConsentFormOpened);

export const getIsRedirectedToBundleOptInPage = () =>
    localStorage.getItem(
        appConstants.localStorage.isRedirectedToBundleOptInPage,
    );

export const getIsRedirectedToBundleSubsPage = () =>
    localStorage.getItem(
        appConstants.localStorage.isRedirectedToBundleSubsPage,
    );

export const getResetConsentIndexDbStatus = () =>
    localStorage.getItem(appConstants.localStorage.resetConsentIndexDbStatus);

export const getUserFirstName = () =>
    localStorage.getItem(appConstants.localStorage.firstName);

export const getUserLastName = () =>
    localStorage.getItem(appConstants.localStorage.lastName);

export const setLoggedInUserMigrated = (value: string) =>
    localStorage.setItem(appConstants.localStorage.loggedInUserMigrated, value);

const setTokenEncyptedMigrated = (value: string) =>
    localStorage.setItem(
        appConstants.localStorage.encryptedTokensMigrated,
        value,
    );

export const setIsRedirectedToAmmwayConsentForm = (value: string) =>
    localStorage.setItem(
        appConstants.localStorage.isRedirectedToAmmwayConsentForm,
        value,
    );

export const setIsRedirectedToBundleOptInPage = (value: string) =>
    localStorage.setItem(
        appConstants.localStorage.isRedirectedToBundleOptInPage,
        value,
    );

export const setIsRedirectedToBundleSubsPage = (value: string) =>
    localStorage.setItem(
        appConstants.localStorage.isRedirectedToBundleSubsPage,
        value,
    );

export const setIsLtdConsentFormOpened = (value: string) =>
    localStorage.setItem(
        appConstants.localStorage.isLtdConsentFormOpened,
        value,
    );

export const setResetConsentIndexDbStatus = (value: string) =>
    localStorage.setItem(
        appConstants.localStorage.resetConsentIndexDbStatus,
        value,
    );

const getTokenEncyptedMigrated = () =>
    localStorage.getItem(appConstants.localStorage.encryptedTokensMigrated);

export const migrateExistingValues = () => {
    if (getTokenEncyptedMigrated() !== "true") {
        migrateLocalStorageValues();
        window.location.reload();
    }
};

const migrateLocalStorageValues = () => {
    const accessToken = localStorage.getItem("accessToken") || "";
    const refreshToken = localStorage.getItem("refreshToken") || "";
    const courseFirebaseToken =
        localStorage.getItem("courseFirebaseToken") || "";
    const webcastsFirebaseToken =
        localStorage.getItem("webcastsFirebaseToken") || "";

    setEncryptedLocalStorageItem(
        accessToken,
        appConstants.localStorage.accessToken,
    );
    setEncryptedLocalStorageItem(
        refreshToken,
        appConstants.localStorage.refreshToken,
    );
    setEncryptedLocalStorageItem(
        webcastsFirebaseToken,
        appConstants.localStorage.webcastsFirebaseToken,
    );
    setEncryptedLocalStorageItem(
        courseFirebaseToken,
        appConstants.localStorage.courseFirebaseToken,
    );
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("courseFirebaseToken");
    localStorage.removeItem("webcastsFirebaseToken");
    setTokenEncyptedMigrated("true");
};

export const setEncryptedLocalStorageItem = (value: string, key: string) => {
    const s3Key = config.s3.xor;
    const encryptedval =
        value?.length && s3Key?.length
            ? encryptAndDecryptXorString(value, s3Key)
            : "";
    localStorage.setItem(key, encryptedval);
};

export const setLocalStorageUserName = (
    firstName: string,
    lastName: string,
) => {
    localStorage.setItem(appConstants.localStorage.firstName, firstName);
    localStorage.setItem(appConstants.localStorage.lastName, lastName);
    config.user.firstName = firstName;
    config.user.lastName = lastName;
    localStorage.setItem(
        appConstants.localStorage.usernameSyncTime,
        moment.now().toString(),
    );
};

export const updateNgTokens = (
    ngAccessToken: string,
    ngRefreshToken: string,
) => {
    setEncryptedLocalStorageItem(
        ngAccessToken,
        appConstants.localStorage.ngAccessToken,
    );
    setEncryptedLocalStorageItem(
        ngRefreshToken,
        appConstants.localStorage.ngRefreshToken,
    );
    config.ngAccesstoken = ngAccessToken;
    config.ngRefreshToken = ngRefreshToken;
};
