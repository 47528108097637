import { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
    AddToCart,
    ConfirmContentPurchase,
    ResponseMessage,
} from "JS/Models/Media";

import {
    EventNames,
    EventActions,
    EventCategories,
    FirebaseEventAction,
} from "JS/Models";
import { useFirebaseLogger } from "./Firebase";
import { PurchaseService } from "JS/Services/Purchase";
import { messaging } from "JS/Helpers/UserMessaging";
import { AxiosResponse } from "axios";

const service = new PurchaseService();

export default service;

export const useAvailableCredits = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [availableCredits, setAvailableCredits] = useState<number>(null);

    const { enqueueSnackbar } = useSnackbar();

    const getAvailableCredits = useCallback(() => {
        setLoading(true);
        return service
            .getAvailableCredits()
            .then((response) => {
                if (response?.status) {
                    setAvailableCredits(response.data.availableCredits);
                } else {
                    enqueueSnackbar(
                        (response as unknown as any).message ||
                            messaging.common.error,
                        {
                            variant: "error",
                        },
                    );
                }
                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err.response.data.message, {
                    variant: "error",
                });
                throw err;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) getAvailableCredits();
    }, [skip]);

    return {
        getAvailableCredits,
        availableCredits,
        loading,
    };
};

export const useRedeemByCredits = () => {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { logFirebaseEvent } = useFirebaseLogger();

    const redeemByCredits = useCallback(
        (
            skuId: string,
            firebaseEvent: Partial<FirebaseEventAction>,
            onFailed?: (msg: string) => void,
        ) => {
            setLoading(true);

            let eventName: EventNames,
                successEventName: EventNames,
                failedEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                action: EventActions.REDEEM,
                category: firebaseEvent?.category,
                contentTitle: firebaseEvent?.contentTitle,
                nId: firebaseEvent?.nId,
                skuId: skuId,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.AUDIOS) {
                    eventName = EventNames.AUDIO_REDEEM_REQUESTED;
                    successEventName = EventNames.AUDIO_REDEEMED;
                    failedEventName = EventNames.AUDIO_REDEEM_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.VIDEOS) {
                    eventName = EventNames.VIDEO_REDEEM_REQUESTED;
                    successEventName = EventNames.VIDEO_REDEEMED;
                    failedEventName = EventNames.VIDEO_REDEEM_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.COURSE) {
                    eventName = EventNames.COURSE_REDEEM_REQUESTED;
                    successEventName = EventNames.COURSE_REDEEMED;
                    failedEventName = EventNames.COURSE_REDEEM_FAILED;
                }
                logFirebaseEvent(eventName, {
                    ...fireEvent,
                    action: EventActions.REDEEM_REQUEST,
                });
            }

            return service
                .redeemContent(skuId)
                .then((response) => {
                    if (!response?.status) {
                        const message =
                            (response as unknown as any)?.message ||
                            messaging.common.error;
                        !!onFailed && onFailed(message);
                        enqueueSnackbar(message, {
                            variant: "error",
                        });

                        if (firebaseEvent) {
                            logFirebaseEvent(failedEventName, {
                                ...fireEvent,
                                action: EventActions.REDEEM_FAILED,
                            });
                        }
                    } else {
                        if (firebaseEvent) {
                            logFirebaseEvent(successEventName, {
                                ...fireEvent,
                                action: EventActions.REDEEM,
                            });
                        }
                        enqueueSnackbar(
                            response?.data?.message ||
                                messaging.purchasing.successRedeemed,
                            {
                                variant: "success",
                            },
                        );
                    }
                    return response;
                })
                .catch((err) => {
                    const errorMessage = err.response.data.message;
                    enqueueSnackbar(errorMessage, {
                        variant: "error",
                    });
                    if (firebaseEvent) {
                        logFirebaseEvent(failedEventName, {
                            ...fireEvent,
                            action: EventActions.REDEEM_FAILED,
                        });
                    }
                    onFailed(errorMessage);
                    return null as AxiosResponse<ResponseMessage>;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        redeemByCredits,
        loading,
    };
};

export const usePurchaseByCard = () => {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { logFirebaseEvent } = useFirebaseLogger();

    const purchaseByCard = useCallback(
        (
            cartId: string,
            quantity: number,
            skuId: string,
            firebaseEvent: Partial<FirebaseEventAction>,
            onFailed?: (msg: string) => void,
        ) => {
            setLoading(true);

            let purchaseRequestedEventName: EventNames,
                purchasedEventName: EventNames,
                purchaseFailedEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                action: EventActions.PURCHASE,
                category: firebaseEvent?.category,
                contentTitle: firebaseEvent?.contentTitle,
                nId: firebaseEvent?.nId,
                skuId: skuId,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.AUDIOS) {
                    purchaseRequestedEventName =
                        EventNames.AUDIO_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.AUDIO_PURCHASED;
                    purchaseFailedEventName = EventNames.AUDIO_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.VIDEOS) {
                    purchaseRequestedEventName =
                        EventNames.VIDEO_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.VIDEO_PURCHASED;
                    purchaseFailedEventName = EventNames.VIDEO_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.COURSE) {
                    purchaseRequestedEventName =
                        EventNames.COURSE_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.COURSE_PURCHASED;
                    purchaseFailedEventName = EventNames.COURSE_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_AUDIOS) {
                    purchaseRequestedEventName =
                        EventNames.GIFT_AUDIO_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.GIFT_AUDIO_PURCHASED;
                    purchaseFailedEventName =
                        EventNames.GIFT_AUDIO_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_VIDEOS) {
                    purchaseRequestedEventName =
                        EventNames.GIFT_VIDEO_PURCHASED_REQUESTED;
                    purchasedEventName = EventNames.GIFT_VIDEO_PURCHASED;
                    purchaseFailedEventName =
                        EventNames.GIFT_VIDEO_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_COURSE) {
                    purchaseRequestedEventName =
                        EventNames.GIFT_COURSE_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.GIFT_COURSE_PURCHASED;
                    purchaseFailedEventName =
                        EventNames.GIFT_COURSE_PURCHASE_FAILED;
                }

                logFirebaseEvent(purchaseRequestedEventName, {
                    ...fireEvent,
                    action: EventActions.PURCHASE_REQUEST,
                });
            }

            return service
                .confirmPurchase(cartId, skuId, quantity)
                .then((response) => {
                    if (!response?.status) {
                        const message =
                            (response as unknown as any)?.message ||
                            messaging.common.error;
                        onFailed && onFailed(message);
                        enqueueSnackbar(message, {
                            variant: "error",
                        });
                        logFirebaseEvent(purchaseFailedEventName, {
                            ...fireEvent,
                            action: EventActions.PURCHASE_FAILED,
                        });
                    } else {
                        enqueueSnackbar(
                            response?.data?.messages ||
                                messaging.purchasing.successPurchased,
                            {
                                variant: "success",
                            },
                        );
                        logFirebaseEvent(purchasedEventName, {
                            ...fireEvent,
                            action: EventActions.PURCHASE,
                        });
                    }
                    return response;
                })
                .catch((err) => {
                    const errorMessage = err.response.data.message;
                    onFailed && onFailed(errorMessage);
                    enqueueSnackbar(errorMessage, { variant: "error" });
                    logFirebaseEvent(purchaseFailedEventName, {
                        ...fireEvent,
                        action: EventActions.PURCHASE_FAILED,
                    });
                    return null as AxiosResponse<ConfirmContentPurchase>;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        purchaseByCard,
        loading,
    };
};

export const useAddContentToCart = () => {
    const [loading, setLoading] = useState(false);
    const [cart, setCart] = useState<AddToCart>(null);

    const { enqueueSnackbar } = useSnackbar();

    const addToCart = useCallback((skuId, quantity) => {
        setLoading(true);

        return service
            .purchaseContent(skuId, quantity)
            .then((response) => {
                if (response?.status) {
                    setCart(response.data);
                } else {
                    enqueueSnackbar((response as unknown as any)?.message, {
                        variant: "error",
                    });
                }
                return response;
            })
            .catch((err) => {
                enqueueSnackbar(err.response.data.message, {
                    variant: "error",
                });
                return null as AxiosResponse<AddToCart>;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return {
        cart,
        addToCart,
        loading,
    };
};

export const useRedeemGiftByCredits = () => {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { logFirebaseEvent } = useFirebaseLogger();

    const redeemGiftByCredits = useCallback(
        (
            skuId: string,
            quantity: number,
            firebaseEvent?: Partial<FirebaseEventAction>,
        ) => {
            setLoading(true);

            let eventName: EventNames,
                successEventName: EventNames,
                failedEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                action: EventActions.REDEEM,
                category: firebaseEvent?.category,
                contentTitle: firebaseEvent?.contentTitle,
                nId: firebaseEvent?.nId,
                skuId: skuId,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.GIFT_AUDIOS) {
                    eventName = EventNames.GIFT_AUDIO_REDEEM_REQUESTED;
                    successEventName = EventNames.GIFT_AUDIO_REDEEMED;
                    failedEventName = EventNames.GIFT_AUDIO_REDEEM_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_VIDEOS) {
                    eventName = EventNames.GIFT_VIDEO_REDEEM_REQUESTED;
                    successEventName = EventNames.GIFT_VIDEO_REDEEMED;
                    failedEventName = EventNames.GIFT_VIDEO_REDEEM_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_COURSE) {
                    eventName = EventNames.GIFT_COURSE_REDEEM_REQUESTED;
                    successEventName = EventNames.GIFT_COURSE_REDEEMED;
                    failedEventName = EventNames.GIFT_COURSE_REDEEM_FAILED;
                }
                logFirebaseEvent(eventName, {
                    ...fireEvent,
                    action: EventActions.REDEEM_REQUEST,
                });
            }

            return service
                .redeemMultipleContent(skuId, quantity)
                .then((response) => {
                    if (!response?.status) {
                        enqueueSnackbar((response as unknown as any).message, {
                            variant: "error",
                        });
                        logFirebaseEvent(failedEventName, {
                            ...fireEvent,
                            action: EventActions.REDEEM_FAILED,
                        });
                    } else {
                        enqueueSnackbar(
                            response?.data?.message ||
                                messaging.purchasing.successRedeemed,
                            {
                                variant: "success",
                            },
                        );
                        logFirebaseEvent(successEventName, {
                            ...fireEvent,
                            action: EventActions.REDEEM,
                        });
                    }
                    return response;
                })
                .catch((err) => {
                    logFirebaseEvent(failedEventName, {
                        ...fireEvent,
                        action: EventActions.REDEEM_FAILED,
                    });

                    enqueueSnackbar(err.response.data.message, {
                        variant: "error",
                    });
                    return null as AxiosResponse<[]>;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        redeemGiftByCredits,
        loading,
    };
};
