import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { routesForContext } from "JS/Routing";
import { config as appConfig } from "JS/Config";
import { clearLocalStorage } from "JS/Helpers";
import { store } from "JS/Redux/Store";
import { resetCourseState } from "JS/Redux/Course";
import { resetEventsState } from "JS/Redux/Event";
import { softResetGiftState } from "JS/Redux/Gift";
import { resetHomeState } from "JS/Redux/Home";
import { resetWebcastState } from "JS/Redux/Webcast";
import { resetMediaState } from "JS/Redux/media";
import { resetNavStack } from "JS/Redux/NavStack";
import { messaging } from "JS/Helpers/UserMessaging";
import { resetRecommendationsState } from "JS/Redux/Recommendations";
import { resetMediaEssentials } from "JS/Redux/MediaEssentials";
import {
    resetGlobalDownloadClickCount,
    resetGlobalDownloadCount,
} from "JS/Redux/DownloadCount";
import {
    deleteDeviceTokenApi,
    getLocalFcmToken,
} from "JS/Helpers/PushNotifications";
import {
    setEncryptedLocalStorageItem,
    updateNgTokens,
} from "JS/Helpers/LocalStorageHelpers";
import { appConstants } from "JS/Helpers/Contants";
import { resetAudioState } from "JS/Redux/Audio";
import { resetVideoState } from "JS/Redux/Video";
import { resetPlaylistResumeMap } from "JS/Redux/Playlist";
import { resetMixedContentState } from "JS/Redux/MixedContent";
import { TokenResponse } from "JS/Models";
import { AppResponse } from "JS/Types";

const setAccessToken = (accessToken: string) => {
    setEncryptedLocalStorageItem(
        accessToken,
        appConstants?.localStorage.accessToken,
    );
    appConfig.accessToken = accessToken;
};

const setRefreshToken = (refreshToken: string) => {
    setEncryptedLocalStorageItem(
        refreshToken,
        appConstants?.localStorage.refreshToken,
    );
    appConfig.refreshToken = refreshToken;
};

export const logoutUser = async () => {
    const token = getLocalFcmToken();
    if (token) {
        try {
            await deleteDeviceTokenApi(appConfig.user.memberId, token);
        } catch (err) {}
    }

    clearLocalStorage();
    store.dispatch(resetAudioState());
    store.dispatch(resetVideoState());
    store.dispatch(resetPlaylistResumeMap());
    store.dispatch(resetCourseState());
    store.dispatch(resetEventsState());
    store.dispatch(softResetGiftState());
    store.dispatch(resetHomeState());
    store.dispatch(resetWebcastState());
    store.dispatch(resetMediaState());
    store.dispatch(resetNavStack());
    store.dispatch(resetRecommendationsState());
    store.dispatch(resetMixedContentState());
    if (
        store?.getState()?.mediaEssentials?.download_warning_cleared_on_logout
    ) {
        store.dispatch(resetGlobalDownloadCount());
    }
    store.dispatch(resetGlobalDownloadClickCount());
    store.dispatch(resetMediaEssentials());
    setTimeout(() => {
        window.location.reload();
    }, 500);
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};
export class BaseService {
    private cmlmManager: AxiosInstance;
    private ngManager: AxiosInstance;
    protected routes = routesForContext()();

    constructor() {
        this.ngManager = this.createAxiosInstance(appConfig.ngBaseUrl, true);
        this.cmlmManager = this.createAxiosInstance(this.routes.server.root());
    }

    private createAxiosInstance(baseURL: string, isNg = false): AxiosInstance {
        const instance = axios.create({
            headers: {
                Accept: "application/json",
                ...(isNg
                    ? {
                          "x-api-key": appConfig.xApiKey,
                          "x-tenant-id": appConfig.xTenantId,
                      }
                    : {}),
            },
            baseURL,
        });

        instance.interceptors.response.use(
            (res) => res,
            async (err) => {
                const originalRequest = err.config;
                if (err.response) {
                    // Access Token was expired.
                    if (
                        isNg
                            ? err.response.status === 403
                            : [401, 498, 419, 400, 499].includes(
                                  err.response.status,
                              ) && !originalRequest._retry
                    ) {
                        if (isRefreshing) {
                            return new Promise(function (resolve, reject) {
                                failedQueue.push({ resolve, reject });
                            })
                                .then((token) => {
                                    if (
                                        originalRequest.headers["authorization"]
                                    ) {
                                        originalRequest.headers[
                                            "authorization"
                                        ] = `Bearer ${token}`;
                                    } else {
                                        const reqFormData =
                                            originalRequest.data;
                                        reqFormData.delete("access_token");
                                        reqFormData.append(
                                            "access_token",
                                            `${token}`,
                                        );
                                    }
                                    originalRequest._retry = true;
                                    return instance(originalRequest);
                                })
                                .catch((err) => Promise.reject(err));
                        }
                        // Rebuilding Token from refresh token.
                        try {
                            originalRequest._retry = true;
                            isRefreshing = true;

                            if (isNg) {
                                const res = await this.ngCall<TokenResponse>(
                                    "post",
                                    appConstants.ngEndpoints.refreshToken,
                                    {
                                        refreshToken: appConfig.ngRefreshToken,
                                        memberId: appConfig?.user?.memberId,
                                        tenantId: "ltd",
                                        clientId: 2,
                                        scope: "media_scope",
                                        platform: "web",
                                    },
                                );

                                if (res?.status && res?.data) {
                                    const ngAccessToken =
                                        res?.data?.accessToken;
                                    const ngRefreshToken =
                                        res?.data?.refreshToken;
                                    updateNgTokens(
                                        ngAccessToken,
                                        ngRefreshToken,
                                    );
                                    originalRequest._retry = true;
                                    processQueue(null, ngAccessToken);
                                    return instance(originalRequest);
                                } else {
                                    isRefreshing = false;
                                    processQueue(err, null);
                                    logoutUser();
                                    return Promise.reject(err);
                                }
                            } else {
                                const formData = new FormData();
                                formData.append(
                                    "refresh_token",
                                    `${appConfig.refreshToken}`,
                                );

                                const rs = await instance.post(
                                    this.routes.server.api.users.rebuildAuth,
                                    formData,
                                );

                                if (
                                    rs &&
                                    rs.status === 200 &&
                                    rs?.data?.response?.status
                                ) {
                                    const { access_token, refresh_token } =
                                        rs.data?.response?.data?.users;
                                    setAccessToken(access_token);
                                    setRefreshToken(refresh_token);
                                    updateNgTokens(access_token, refresh_token);
                                    appConfig.ngAccesstoken = access_token;
                                    appConfig.ngRefreshToken = refresh_token;
                                    if (
                                        originalRequest.headers["authorization"]
                                    ) {
                                        originalRequest.headers[
                                            "authorization"
                                        ] = `Bearer ${access_token}`;
                                    } else {
                                        const reqFormData =
                                            originalRequest.data;
                                        reqFormData.delete("access_token");
                                        reqFormData.append(
                                            "access_token",
                                            `${access_token}`,
                                        );
                                    }
                                    originalRequest._retry = true;
                                    processQueue(null, access_token);
                                    return instance(originalRequest);
                                } else {
                                    // Refresh Token is expired.
                                    isRefreshing = false;
                                    processQueue(err, null);
                                    logoutUser();
                                    return Promise.reject(err);
                                }
                            }
                        } catch (err) {
                            // Refresh Token is expired.
                            isRefreshing = false;
                            processQueue(err, null);
                            logoutUser();
                            return Promise.reject(err);
                        } finally {
                            isRefreshing = false;
                        }
                    }
                    return Promise.reject(err);
                }
            },
        );

        return instance;
    }

    protected doXHR<T>(
        config: AxiosRequestConfig,
    ): Promise<AxiosResponse<T, any>> {
        return this.cmlmManager
            .request<T>(config)
            .then((res) => {
                return res;
            })
            .catch(async (err): Promise<any> => {
                if (err.message === messaging.apiError.network) {
                    return {
                        data: {
                            response: {
                                data: {},
                                status: false,
                                message: messaging.apiError.somethingWentWrong,
                            },
                            data: {},
                            status: false,
                            message: messaging.apiError.somethingWentWrong,
                        },
                        status: 0,
                        statusText: "",
                        config: {},
                        headers: {},
                    };
                } else {
                    throw err;
                }
            });
    }

    protected async ngCall<T>(
        method: "get" | "post" | "put",
        url: string,
        data?: any,
        reqConfig?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        try {
            if (!appConfig?.ngAccesstoken) {
                const formData = new FormData();

                formData.append("tenantId", appConfig.xTenantId);
                formData.append("clientId", "2");
                formData.append("memberId", appConfig.user.memberId);
                formData.append("scope", "media_scope");
                formData.append("access_token", appConfig.accessToken);
                formData.append("platform", "web");

                const res = (
                    await this.doXHR<AppResponse<TokenResponse>>({
                        url: appConstants.ngEndpoints.accessToken,
                        method: "POST",
                        data: formData,
                    })
                ).data;

                const responseData = res?.response?.data;

                if (!res?.response?.status)
                    throw new Error(res?.response?.message);

                const ngAccessToken = responseData?.accessToken;
                const ngRefreshToken = responseData?.refreshToken;
                updateNgTokens(ngAccessToken, ngRefreshToken);
            }

            switch (method) {
                case "get":
                    return this.ngManager.get<T>(url, {
                        ...data,
                        ...reqConfig,
                    });
                case "post":
                    return this.ngManager.post<T>(url, data, reqConfig);
                case "put":
                    return this.ngManager.put<T>(url, data, reqConfig);
                default:
                    throw new Error(`Unsupported method: ${method}`);
            }
        } catch (err) {
            throw err;
        }
    }
}

export const routes = routesForContext()();
const axiosInstance = axios.create({
    headers: {
        Accept: "application/json",
    },
    baseURL: routes.server.root(),
});
export function doXHR<T>(
    config: AxiosRequestConfig,
): Promise<AxiosResponse<T, any>> {
    return axiosInstance
        .request<T>(config)
        .then((res) => {
            return res;
        })
        .catch(async (err): Promise<any> => {
            if (err.message === messaging.apiError.network) {
                return {
                    data: {
                        response: {
                            data: {},
                            status: false,
                            message: messaging.apiError.somethingWentWrong,
                        },
                        data: {},
                        status: false,
                        message: messaging.apiError.somethingWentWrong,
                    },
                    status: 0,
                    statusText: "",
                    config: {},
                    headers: {},
                };
            } else {
                throw err;
            }
        });
}
