import { useState, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { FavoritesService } from "JS/Services/Favorites";
import {
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
} from "JS/Models";
import moment from "moment";
import { EventsHelper } from "JS/Services/Events/EventsHelper";
import {
    setGlobalFavNids,
    setMarkFavNid,
    setUnmarkFavNid,
} from "JS/Redux/Favorites";
import { useFirebaseLogger } from "./Firebase";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";

const service = new FavoritesService();
const FAV_CONTENT_SYNC_TIME = "FAVORITES_CONTENT_SYNC_TIME";

export const useGetFavorites = (skip: boolean = false) => {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { setGlobalFavNids } = useGlobalFavorites();

    const refetch = useCallback(
        (showLoader: boolean = true, showSnackbar: boolean = false) => {
            setLoading(showLoader);
            service
                .getFavorites()
                .then((response) => {
                    if (!response?.status) throw Error(messaging.common.error);
                    const mappedFavNids = response.data.map((favNid) =>
                        favNid.toString(),
                    );
                    setGlobalFavNids(mappedFavNids);
                    localStorage.setItem(
                        FAV_CONTENT_SYNC_TIME,
                        moment.now().toString(),
                    );

                    if (showSnackbar) {
                        if (response.status) {
                            enqueueSnackbar(messaging.favorites.success, {
                                variant: "success",
                            });
                        } else {
                            enqueueSnackbar(messaging.common.error, {
                                variant: "warning",
                            });
                        }
                    }
                    return response;
                })
                .catch((err) => {
                    throw err;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    useEffect(() => {
        const syncTime = +localStorage.getItem(FAV_CONTENT_SYNC_TIME);
        const have24HoursPassed =
            moment.now() - syncTime >= EventsHelper.ONE_DAY;
        if (have24HoursPassed || !skip) refetch(!skip);
    }, [skip]);

    return {
        refetch,
        loading,
    };
};

export const useMarkFavorites = () => {
    const [loading, setLoading] = useState(false);
    const { logFirebaseEvent } = useFirebaseLogger();
    const { markGlobalFavNid } = useGlobalFavorites();

    const markFavContentNid = useCallback(
        (nid: string, firebaseEvent?: Partial<FirebaseEventAction>) => {
            setLoading(true);
            let successEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                ...firebaseEvent,
                action: EventActions.Favorite,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.GIFT_AUDIOS) {
                    successEventName = EventNames.GIFT_AUDIO_FAVORITE;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_VIDEOS) {
                    successEventName = EventNames.GIFT_VIDEO_FAVORITE;
                }

                if (firebaseEvent?.category === EventCategories.AUDIOS) {
                    successEventName = EventNames.AUDIO_FAVORITE;
                }
                if (firebaseEvent?.category === EventCategories.VIDEOS) {
                    successEventName = EventNames.VIDEO_FAVORITE;
                }
            }

            return service
                .markFavorite(nid)
                .then((response) => {
                    if (response?.status) {
                        markGlobalFavNid(nid);
                        logFirebaseEvent(successEventName, fireEvent);

                        return response;
                    } else throw Error(messaging.common.error);
                })
                .catch((err) => {
                    throw err;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        markFavContentNid,
        loading,
    };
};

export const useUnMarkFavorites = () => {
    const [loading, setLoading] = useState(false);
    const { logFirebaseEvent } = useFirebaseLogger();
    const { unmarkGlobalFavNid } = useGlobalFavorites();

    const unMarkFavContentNid = useCallback(
        (nid: string, firebaseEvent?: Partial<FirebaseEventAction>) => {
            setLoading(true);

            let successEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                ...firebaseEvent,
                action: EventActions.UnFavorite,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.GIFT_AUDIOS) {
                    successEventName = EventNames.GIFT_AUDIO_UNFAVORITE;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_VIDEOS) {
                    successEventName = EventNames.GIFT_VIDEO_UNFAVORITE;
                }

                if (firebaseEvent?.category === EventCategories.AUDIOS) {
                    successEventName = EventNames.AUDIO_UNFAVORITE;
                }
                if (firebaseEvent?.category === EventCategories.VIDEOS) {
                    successEventName = EventNames.VIDEO_UNFAVORITE;
                }
            }

            return service
                .unMarkFavorite(nid)
                .then((response) => {
                    if (response?.status) {
                        unmarkGlobalFavNid(nid);
                        logFirebaseEvent(successEventName, fireEvent);

                        return response;
                    } else throw Error(messaging.common.error);
                })
                .catch((err) => {
                    throw err;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        unMarkFavContentNid,
        loading,
    };
};

export const useGlobalFavorites = () => {
    const dispatch = useAppDispatch();
    const favNids = useAppSelector((state) => state.favorites.favNids);

    return {
        favNids: favNids,

        setGlobalFavNids: (favNids: string[]) => {
            dispatch(setGlobalFavNids(favNids));
        },

        markGlobalFavNid: (nid: string) => {
            dispatch(setMarkFavNid(nid));
        },

        unmarkGlobalFavNid: (nid: string) => {
            dispatch(setUnmarkFavNid(nid));
        },
    };
};
