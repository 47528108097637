import { ContentMedia } from "./Common";

export interface ResponseMessage {
    message: string;
}
export interface AvailableCredits {
    availableCredits: number;
}
export interface AddToCart {
    cartId: string;
    messages: string;
    orderId: string;
    processingFee: number;
    shippingFee: number;
    subtotal: number;
    tax: number;
    total: number;
}

export interface ConfirmContentPurchase {
    cartId: any;
    messages: string;
    orderId: string;
    processingFee: number;
    shippingFee: number;
    subtotal: number;
    tax: number;
    total: number;
}

export interface Members {
    member_id: string;
    first_name: string;
    last_name: string;
    no_of_downlines: number;
    pin_level: string;
}

export interface MembersGroup {
    group_name: string;
    members: Members[];
}

export interface GiftRemainingQuantity {
    sku: string;
    quantity: number;
}

export interface Gifts {
    nid: string;
    title: string;
    sku_id: string;

    sender?: {
        first_name: string;
        last_name: string;
        member_id: string;
        sending_date: string;
        sending_date_time: string;
    };
    attach_skus?: {
        prospect_sku_id?: string;
        member_sku_id?: string;
    };
    is_redeemable_by_tokens: string;
    release_date: string;
    image_file_name: string;
    image_url_postfix: string;
    image_bucket_name: string;
    publish_free: string;
    content_price: string;
    description: string;
    number_of_tokens: string;
    content_item_type_name: string;
    published_date: string;
    content_item_type_id: string;
    content_available: string;
    access: string;
    no_of_files: string;
    image_url_prefix: string;
    base_price: string;
    media_url_prefix: string;
    media_url_postfix: string;
    media_file_name: string;
    media_bucket_name: string;
    content_category_id: string;
    content_category_title: string;
    length: string;
    content_category_thumb: string;
    quantity: number;
    is_cep: false;
    is_favourite: false;
    is_purchased: false;
    attributes: {};
    productLists: [];
    media: ContentMedia[];
    isReceived: boolean;
    acquired_date: string;
    played: string;
    created: string;
    showDisclosure: number;
}

export interface GiftsGiven extends Gifts {
    receiver: {
        first_name: string;
        last_name: string;
        member_id: string;
    };
}

export interface GiftsAcquiredDate {
    sku_id: string;
    acquired_date: string;
    content_item_type_id: string;
    content_item_type_name: string;
}

export interface AcquiredDate {
    [key: string]: string;
}

export interface SendGift {
    nid: string;
    sku: string;
    category: string;
    title: string;
}

export enum AllTabs {
    AUDIOS = "Audio",
    VIDEOS = "Video",
    COURSES = "Course",
}
