export const appConstants = {
    downloadingQueue: {
        downloadingQueueLimit: 50,
    },
    firebaseError: {
        permDenied: "Permission denied",
    },
    localStorage: {
        fcmToken: "fcmToken",
        webcastUUID: "Webcast_UUID",
        refreshedOnInitialized: "refreshed_on_initialized",
        playlistMigrated: "playlistMigrated",
        loggedInUserMigrated: "loggedInUserMigrated",
        firstName: "firstName",
        lastName: "lastName",
        usernameSyncTime: "usernameSyncTime",
        encryptedTokensMigrated: "encryptedTokensMigrated",
        accessToken: "encryptedAccessToken",
        refreshToken: "encryptedRefreshToken",
        courseFirebaseToken: "encryptedCourseFirebaseToken",
        webcastsFirebaseToken: "encryptedWebcastsFirebaseToken",
        isResumeMigrated: "isResumeMigrated",
        isRedirectedToAmmwayConsentForm: "isRedirectedToAmmwayConsentForm",
        isLtdConsentFormOpened: "isLtdConsentFormOpened",
        isRedirectedToBundleOptInPage: "isRedirectedToBundleOptInPage",
        isRedirectedToBundleSubsPage: "isRedirectedToBundleSubsPage",
        resetConsentIndexDbStatus: "resetConsentIndexDbStatus",
        ngAccessToken: "ngAccesstoken",
        ngRefreshToken: "ngRefreshtoken",
    },
    ngEndpoints: {
        videoListing: (memberId: string) =>
            `media/users/${memberId}/videos/optimized`,
        videoDetail: (nid: string) => `/media/videos/${nid}`,
        audioListing: (memberId: string) =>
            `media/users/${memberId}/audios/optimized`,
        audioDetail: (nid: string) => `/media/audios/${nid}`,
        favoritesListing: (memberId: string) =>
            `/media/users/${memberId}/favorites`,
        markFavorite: "/media/mark-favorite",
        unmarkFavorite: "/media/un-mark-favorite",
        accessToken: "/oauth/user/token",
        refreshToken: "v1/users/token/refresh",
        availableCredits: (memberId: string) =>
            `/media/users/${memberId}/get-available-credits`,
        purchaseContent: "/media/purchase-content",
        redeemContent: "/media/redeem-content-for-self",
        redeemMultipleContent: "/media/redeem-multiple-content",
        confirmPurchase: "/media/confirm-purchase-content",
    },
};
export const nidControlTypes = [
    "offlinePlaylist",
    "audio",
    "video",
    "audioFavorite",
    "videoFavorite",
];

//in ms
export const PLAYER_PROGRESS_INTERVAL = 2000;
export const CONTENT_TILE_SIZE = 160;
