import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BundleSubscriptionState {
    showBundleSubscriptionPopup: boolean;
    loadingBundleSubscription: boolean;
    ssoRedirectionUrl: string;
}

const initialState = {
    showBundleSubscriptionPopup: false,
    loadingBundleSubscription: false,
    ssoRedirectionPlace: "",
};

export const bundleSubscriptionSlice = createSlice({
    name: "bundleSubscriptionState",
    initialState,
    reducers: {
        setBundleSubscriptionPopupState: (
            state,
            data: PayloadAction<{ [key: string]: boolean }>,
        ) => {
            state.showBundleSubscriptionPopup =
                data.payload.showBundleSubscriptionPopup;
            state.loadingBundleSubscription =
                data.payload.loadingBundleSubscription;
            return state;
        },
        setShowBundleSubscriptionPopup: (
            state,
            data: PayloadAction<{ [key: string]: boolean }>,
        ) => {
            state.showBundleSubscriptionPopup =
                data.payload.showBundleSubscriptionPopup;
            return state;
        },
        setSsoRedirectionPlace: (
            state,
            data: PayloadAction<{ [key: string]: string }>,
        ) => {
            state.ssoRedirectionPlace = data.payload.ssoRedirectionPlace;
            return state;
        },

        setBundleSubscriptionLoadingState: (
            state,
            data: PayloadAction<{ [key: string]: boolean }>,
        ) => {
            state.loadingBundleSubscription =
                data.payload.loadingBundleSubscription;
            return state;
        },
        resetRedirectionState: () => initialState,
    },
});

export const {
    setBundleSubscriptionPopupState,
    setBundleSubscriptionLoadingState,
    setShowBundleSubscriptionPopup,
    resetRedirectionState,
    setSsoRedirectionPlace,
} = bundleSubscriptionSlice.actions;
