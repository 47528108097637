import { createContext, useContext, useEffect, useState } from "react";
import { useLogin, useSeamlessUpdate } from "JS/React/Hooks/Users";
import { clearLocalStorage, isBetaEnvironment } from "JS/Helpers";
import { useSnackbar } from "notistack";
import { config } from "JS/Config";
import {
    EventNames,
    EventCategories,
    EventActions,
    ILogin,
    SeamlessUpdateResponse,
    User,
} from "JS/Models";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import {
    useGetUserMediaApp,
    useResetGlobalState,
    useGlobalMedia,
} from "../Hooks/Media";
import { messaging } from "JS/Helpers/UserMessaging";
import { useDispatch } from "react-redux";
import { resetDownloadState } from "JS/Redux/download";
import { useUserHistory } from "../Hooks/UserHistory";
import { useMediaEssentials } from "../Hooks/MediaEssentials";
import { AppAlternativeResponse } from "JS/Types";
import { courseFirebaseService } from "JS/Services/Firebase";
import { setAudioUpdateRequired } from "JS/Redux/Audio";
import { setVideoUpdateRequired } from "JS/Redux/Video";
import { setCourseUpdateRequired } from "JS/Redux/Course";
import {
    setEncryptedLocalStorageItem,
    setLocalStorageUserName,
    updateNgTokens,
} from "JS/Helpers/LocalStorageHelpers";
import { appConstants } from "JS/Helpers/Contants";
import { useSyncFirebaseResumeData } from "../Hooks/Firebase/Resume";
import { MediaEssentials } from "JS/Models/MediaEssentials";
import { resetHomeState } from "JS/Redux/Home";
import { setRetContentUpdateRequired } from "JS/Redux/RetiredContent";

interface ContextType {
    loginStatus: boolean;
    isAuthenticated: boolean;
    loading: boolean;
    sessionLoading: boolean;
    signIn: (loginData: ILogin) => Promise<any>;
    setLoginStatus: (status: boolean) => void;
    redirectedToWebcast: boolean;
    setRedirectedToWebcast: (status: boolean) => void;
}

export interface AuthenticationProviderProps {
    children: React.ReactNode;
    isOnline?: boolean;
}
export const AuthenticationContext = createContext<ContextType | undefined>(
    undefined,
);
export const useAuth = () => useContext(AuthenticationContext);

export const AuthenticationProvider = (props: AuthenticationProviderProps) => {
    const { children, isOnline } = props;

    const { login } = useLogin();
    const { enqueueSnackbar } = useSnackbar();
    const { logFirebaseEvent } = useFirebaseLogger();
    const { update } = useSeamlessUpdate(true);
    const { resetReduxState, softResetReduxState, resetLoginState } =
        useResetGlobalState();

    const { refetch: getMediaAppUsers } = useGetUserMediaApp();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginStatus, setLoginStatus] = useState(true);
    const [sessionLoading, setSessionLoading] = useState(true);
    const { addUser } = useUserHistory();
    const [redirectedToWebcast, setRedirectedToWebcast] = useState(false);
    const { refetch: fetchMediaEssentials } = useMediaEssentials(true);

    const { lastLoggedInUser, setLastLoggedInUser } = useGlobalMedia();

    const { syncFirebaseData } = useSyncFirebaseResumeData();
    const dispatch = useDispatch();
    const logout = () => {
        resetReduxState();
        clearLocalStorage();
    };

    const setUserData = (
        accessToken: string,
        refreshToken: string,
        ngAccessToken: string,
        ngRefreshToken: string,
        memberId: string,
    ) => {
        setEncryptedLocalStorageItem(
            accessToken,
            appConstants.localStorage.accessToken,
        );
        localStorage.setItem("memberId", memberId);
        setEncryptedLocalStorageItem(
            refreshToken,
            appConstants.localStorage.refreshToken,
        );
        updateNgTokens(ngAccessToken, ngRefreshToken);
        config.accessToken = accessToken;
        config.user.memberId = memberId;
        config.refreshToken = refreshToken;
        config.ngAccesstoken = ngAccessToken;
        config.ngRefreshToken = ngRefreshToken;
    };

    const loadSession = async () => {
        const token = config.accessToken;
        if (token && token.length) {
            try {
                setSessionLoading(true);
                var essentials: MediaEssentials;
                var response: AppAlternativeResponse<SeamlessUpdateResponse>;
                if (!!config.s3.accessKey && !!config.s3.secretAccessKey) {
                    essentials = await fetchMediaEssentials();
                    if (
                        !!essentials &&
                        essentials.allow_launch_seamless_update_react === false
                    ) {
                        response = {
                            key: 0,
                            data: null,
                            message: null,
                            status: !!config.accessToken,
                        };
                        courseFirebaseService.logFirebaseEvent(
                            EventNames.SEAMLESS_SKIPPED,
                            {
                                action: EventActions.SEAMLESS_SKIPPED,
                            },
                        );
                    } else response = await update();
                } else response = await update();
                if (!essentials) {
                    essentials = await fetchMediaEssentials();
                }
                if (
                    response?.data?.content_update === 1 &&
                    essentials?.allow_rule_engine_requests
                ) {
                    softResetReduxState();
                }

                if (
                    isBetaEnvironment()
                        ? response?.data?.beta_version_mismatch === 1
                        : response?.data?.version_mismatch === 1
                ) {
                    window.location.reload();
                }

                if (response.status === true) {
                    setIsAuthenticated(true);
                } else {
                    if (
                        !response.status &&
                        ["419", "499", "400", "419", "498", "401"].includes(
                            response?.key?.toString(),
                        )
                    ) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                    }
                }
            } catch (err) {
                if (
                    err?.toJson &&
                    ["419", "499", "400", "419", "498", "401"].includes(
                        err?.toJSON()?.status?.toString(),
                    )
                ) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } finally {
                setSessionLoading(false);
            }
        } else {
            setIsAuthenticated(false);
            setSessionLoading(false);
        }
    };

    const authenticateUser = () => {
        setIsAuthenticated(true);

        logFirebaseEvent(EventNames.LOGGED_IN, {
            action: EventActions.LOGIN,
            category: EventCategories.GENERAL,
        });
        logFirebaseEvent(EventNames.APPLICATION_LAUNCHED, {
            action: EventActions.APPLICATION_LAUNCHED,
            category: EventCategories.GENERAL,
        });
    };

    const handleUserSwitch = (memberId: User["member_id"]) => {
        if (memberId !== lastLoggedInUser) {
            resetLoginState();
        }
        dispatch(setRetContentUpdateRequired());
        dispatch(setAudioUpdateRequired());
        dispatch(setVideoUpdateRequired());
        dispatch(setCourseUpdateRequired());
        setLastLoggedInUser(memberId);
    };

    const signIn = async (loginData: ILogin) => {
        setLoading(true);
        login(loginData)
            .then(async (res) => {
                const response = res.response;

                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                    setLoginStatus(false);
                } else {
                    const user = response.data[0];
                    setUserData(
                        user.access_token,
                        user.refresh_token,
                        user.accessToken, //ngAccessToken
                        user.refreshToken, //ngRefreshToken
                        user.member_id,
                    );
                    setLocalStorageUserName(user.first_name, user.last_name);
                    handleUserSwitch(user.member_id);
                    dispatch(resetHomeState());
                    dispatch(resetDownloadState());

                    addUser(user);
                    await loadSession();
                    syncFirebaseData();

                    if (isBetaEnvironment()) {
                        const data = await getMediaAppUsers();

                        if (!!data ? !data.includes(user.member_id) : false) {
                            setIsAuthenticated(false);
                            enqueueSnackbar(
                                `${messaging?.common?.notAllowedToLink}`,
                                {
                                    variant: "warning",
                                },
                            );
                            logout();
                        } else {
                            authenticateUser();
                        }
                    } else {
                        authenticateUser();
                    }
                }
            })
            .catch((err) => {
                setIsAuthenticated(false);
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                setLoginStatus(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (isOnline) {
            setSessionLoading(true);
            setTimeout(() => loadSession(), 1000);
        }
    }, [isOnline]);

    return (
        <AuthenticationContext.Provider
            value={{
                loginStatus,
                setLoginStatus,
                isAuthenticated,
                loading,
                sessionLoading,
                signIn,
                redirectedToWebcast,
                setRedirectedToWebcast,
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};
