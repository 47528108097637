import { useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
    getIsRedirectedToBundleSubsPage,
    setIsRedirectedToBundleSubsPage,
} from "JS/Helpers/LocalStorageHelpers";
import { messaging } from "JS/Helpers/UserMessaging";
import { useAppDispatch } from "JS/Redux/Store";
import { BundleSubscriptionService } from "JS/Services/BundleSubcription/BundleSubscriptionService";
import {
    setBundleSubscriptionLoadingState,
    setBundleSubscriptionPopupState,
    setShowBundleSubscriptionPopup,
    setSsoRedirectionPlace,
} from "JS/Redux/BundleSubscription";

export const useBundleSubscription = (skip: boolean = false) => {
    const dispatch = useAppDispatch();
    const service = new BundleSubscriptionService();
    const { enqueueSnackbar } = useSnackbar();

    const getBundleSubscriptionStatus = useCallback(() => {
        if (getIsRedirectedToBundleSubsPage() === "true") {
            setIsRedirectedToBundleSubsPage("false");

            dispatch(
                setBundleSubscriptionPopupState({
                    showBundleSubscriptionPopup: false,
                    loadingBundleSubscription: false,
                }),
            );
            return;
        }

        dispatch(
            setBundleSubscriptionLoadingState({
                loadingBundleSubscription: true,
            }),
        );
        return service
            .getBundleSubscriptionStatus()
            .then((res) => {
                if (!res?.response?.status) {
                    enqueueSnackbar(
                        res?.response?.message || messaging.common.error,
                        {
                            variant: "error",
                        },
                    );
                }

                const resData = res.response?.data;

                dispatch(
                    setShowBundleSubscriptionPopup({
                        showBundleSubscriptionPopup:
                            !!resData?.isRecentNewPaidMemberWithoutBundle,
                    }),
                );
                dispatch(
                    setSsoRedirectionPlace({
                        ssoRedirectionPlace: resData?.ssoRedirectionPlace,
                    }),
                );

                return resData;
            })
            .catch((err) => {
                enqueueSnackbar(messaging.common.error, {
                    variant: "error",
                });
                throw err;
            })
            .finally(() =>
                dispatch(
                    setBundleSubscriptionLoadingState({
                        loadingBundleSubscription: false,
                    }),
                ),
            );
    }, []);

    useEffect(() => {
        if (!skip) getBundleSubscriptionStatus();
    }, [skip]);

    return {
        getBundleSubscriptionStatus,
    };
};
