import {
    Content,
    Gifts,
    ResumeContent,
    VideosAndGifts,
    ContentWithMedia,
} from "JS/Models";
import { useGlobalVideos } from "./Video";
import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { sortArrayByKey } from "JS/Helpers";
import { useGlobalGifts } from "./Gifts";
import { useMiniPlayer } from "./MiniPlayer";
import { useGlobalAudios } from "./Audio";
import { AudioDexie, VideoDexie } from "JS/Database/Dexie";
import { usePlaylistResume } from "./Playlist";
import { useGlobalPlaylistControls } from "./PlaylistControls";
import { OnProgressProps } from "react-player/base";

export const useResumeRecentlyVideo = (
    video: VideosAndGifts,
    contentId: string,
    setCurrentPlayingState: React.Dispatch<
        React.SetStateAction<boolean>
    > = () => {},
) => {
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isReadyToPlay, setIsReadyToPlay] = useState<boolean>(false);

    const playerRef = useRef<any>();

    const { miniPlayer } = useMiniPlayer();

    useEffect(() => {
        if (!miniPlayer.url) {
            setIsReady(false);
            setCurrentPlayingState(true);
        }
    }, [miniPlayer]);

    const {
        videos,
        resumeUserVideos,
        updateRecentlyPlayed,
        updateResumeVideos,
        setGlobalVideos,
    } = useGlobalVideos();

    const {
        receivedMemberVideoGifts,
        receivedProspectVideoGifts,
        setGlobalGifts,
    } = useGlobalGifts();

    const onMediaPlayerReady = useCallback(() => {
        setIsReadyToPlay(true);
        if (!isReady && video) {
            const resumeData: ResumeContent = resumeUserVideos
                ? resumeUserVideos.find((x) => x.nid === video.nid)
                : ({} as ResumeContent);
            if (
                resumeData &&
                Math.ceil(+resumeData.lastPlayed) !==
                    Math.ceil(+resumeData.duration)
            ) {
                playerRef?.current?.seekTo(resumeData.lastPlayed, "seconds");
            }
            handleRecentlyPlayed();
            setIsReady(true);
        }
    }, [isReady, video]);

    const onPlayerProgress = async (state: OnProgressProps) => {
        const resumeData: ResumeContent = resumeUserVideos
            ? resumeUserVideos?.find((x) => x.nid === video?.nid)
            : ({} as ResumeContent);

        if (
            state.playedSeconds > 5 &&
            (!resumeData ||
                !resumeData?.duration ||
                state.playedSeconds < +resumeData?.duration - 2)
        ) {
            const data: ResumeContent = {
                nid: video.nid,
                sku_id: video.sku_id,
                title: video.title,
                lastPlayed: playerRef.current.getCurrentTime(),
                maximumPlayed: playerRef.current.getCurrentTime(),
            };
            updateResumeVideos(data);
        }
    };

    const handleContentCompleted = () => {
        const data: ResumeContent = {
            nid: video.nid,
            sku_id: video.sku_id,
            title: video.title,
            lastPlayed: "0",
            maximumPlayed: "0",
            lastCompletedTime: playerRef.current.getCurrentTime(),
            duration: playerRef.current.getDuration(),
        };
        updateResumeVideos(data);
    };

    const handleRecentlyPlayed = () => {
        const timeStamp = moment().unix().toString();
        if (videos?.filter((video) => video.nid === contentId).length > 0) {
            const toSaveVideos: Content[] = videos.map((d) => {
                if (d.nid === contentId) {
                    return {
                        ...d,
                        played: timeStamp,
                    };
                } else {
                    return d;
                }
            });
            setGlobalVideos(toSaveVideos);
        } else if (
            receivedMemberVideoGifts?.filter((video) => video.nid === contentId)
                .length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] = receivedMemberVideoGifts.map(
                (d) => {
                    if (d.nid === contentId) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                },
            );
            setGlobalGifts({
                receivedMemberVideoGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectVideoGifts.map((d) => {
                    if (d.nid === contentId) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedProspectVideoGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }
        updateRecentlyPlayed({ ...video, played: timeStamp });
    };

    return {
        playerRef,
        isReady,
        setIsReady,
        onMediaPlayerReady,
        onPlayerProgress,
        handleContentCompleted,
        handleRecentlyPlayed,
        setIsReadyToPlay,
        isReadyToPlay,
    };
};

export const useResumeRecentlyAudio = (
    audio: Gifts | ContentWithMedia,
    contentId: string,
) => {
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isReadyToPlay, setIsReadyToPlay] = useState<boolean>(false);

    const playerRef = useRef<any>();

    const { miniPlayer } = useMiniPlayer();

    useEffect(() => {
        if (!miniPlayer.url && audio && isReady) {
            onMediaPlayerReady(true);
        }
    }, [miniPlayer]);

    const {
        audios,
        resumeUserAudios,
        updateRecentlyPlayed,
        setGlobalAudios,
        updateResumeAudios,
    } = useGlobalAudios();

    const {
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        setGlobalGifts,
    } = useGlobalGifts();

    const handleRecentlyPlayed = () => {
        const timeStamp = moment().unix().toString();

        if (audios?.filter((video) => video.nid === contentId).length > 0) {
            const toSaveAudios: Content[] = audios.map((d) => {
                if (d.nid === contentId) {
                    return {
                        ...d,
                        played: timeStamp,
                    };
                } else {
                    return d;
                }
            });
            setGlobalAudios(toSaveAudios);
        } else if (
            receivedMemberAudioGifts?.filter((video) => video.nid === contentId)
                .length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] = receivedMemberAudioGifts.map(
                (d) => {
                    if (d.nid === contentId) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                },
            );
            setGlobalGifts({
                receivedMemberAudioGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectAudioGifts.map((d) => {
                    if (d.nid === contentId) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedProspectAudioGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }
        updateRecentlyPlayed({ ...audio, played: timeStamp });
    };

    const onMediaPlayerReady = useCallback(
        (must?: boolean) => {
            setIsReadyToPlay(true);
            if ((!isReady || must) && audio) {
                const resumeData: ResumeContent = resumeUserAudios
                    ? resumeUserAudios.find((x) => x.nid === audio?.nid)
                    : ({} as ResumeContent);
                if (
                    resumeData &&
                    Math.ceil(+resumeData.lastPlayed) !==
                        Math.ceil(+resumeData.duration)
                ) {
                    playerRef?.current?.seekTo(
                        resumeData.lastPlayed,
                        "seconds",
                    );
                }
                setIsReady(true);
            }
        },
        [isReady, audio, resumeUserAudios],
    );

    const onPlayerProgress = async (state: OnProgressProps) => {
        const resumeData: ResumeContent = resumeUserAudios
            ? resumeUserAudios.find((x) => x.nid === audio?.nid)
            : ({} as ResumeContent);
        if (
            state.playedSeconds > 5 &&
            (!resumeData ||
                !resumeData?.duration ||
                state.playedSeconds < +resumeData?.duration - 2)
        ) {
            const data: ResumeContent = {
                nid: audio?.nid,
                sku_id: audio?.sku_id,
                title: audio?.title,
                lastPlayed: playerRef?.current?.getCurrentTime(),
                maximumPlayed: playerRef?.current?.getCurrentTime(),
                duration: playerRef?.current?.duration,
            };
            updateResumeAudios(data);
        }
    };

    const handleContentCompleted = () => {
        const data: ResumeContent = {
            nid: audio?.nid,
            sku_id: audio?.sku_id,
            title: audio?.title,
            lastPlayed: "0",
            maximumPlayed: "0",
            lastCompletedTime: playerRef?.current?.getCurrentTime(),
            duration: playerRef?.current?.getDuration(),
        };
        updateResumeAudios(data);
    };

    return {
        playerRef,
        isReady,
        setIsReady,
        onMediaPlayerReady,
        onPlayerProgress,
        handleContentCompleted,
        handleRecentlyPlayed,
        isReadyToPlay,
        setIsReadyToPlay,
    };
};

export const useResumeRecentlyOfflineContent = (
    content: AudioDexie | VideoDexie,
    type: "audio" | "video",
) => {
    const contentNid = content?.id?.split("-")[1]
        ? content?.id?.split("-")[1]
        : "0";

    const [isReady, setIsReady] = useState<boolean>(false);
    const [isReadyToPlay, setIsReadyToPlay] = useState<boolean>(false);

    const playerRef = useRef<any>();

    const {
        audios,
        resumeUserAudios,
        updateRecentlyPlayed: updateRecentlyPlayedAudios,
        updateResumeAudios,
        setGlobalAudios,
    } = useGlobalAudios();

    const {
        videos,
        resumeUserVideos,
        updateRecentlyPlayed: updateRecentlyPlayedVideos,
        updateResumeVideos,
        setGlobalVideos,
    } = useGlobalVideos();

    const {
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        receivedMemberVideoGifts,
        receivedProspectVideoGifts,
        setGlobalGifts,
    } = useGlobalGifts();

    const handleRecentlyAudioPlayed = () => {
        const timeStamp = moment().unix().toString();
        let contentId = content?.id.split("-")[1];
        let audio: Content | Gifts;

        if (audios?.filter((a) => a.nid === contentId).length > 0) {
            const toSaveAudios: Content[] = audios.map((d) => {
                if (d.nid === contentId) {
                    audio = {
                        ...d,
                        played: timeStamp,
                    };
                    return audio;
                } else {
                    return d;
                }
            });
            setGlobalAudios(toSaveAudios);
        } else if (
            receivedMemberAudioGifts?.filter((video) => video.nid === contentId)
                .length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] = receivedMemberAudioGifts.map(
                (d) => {
                    if (d.nid === contentId) {
                        audio = {
                            ...d,
                            played: timeStamp,
                        };
                        return audio;
                    } else {
                        return d;
                    }
                },
            );
            setGlobalGifts({
                receivedMemberAudioGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectAudioGifts.map((d) => {
                    if (d.nid === contentId) {
                        audio = {
                            ...d,
                            played: timeStamp,
                        };
                        return audio;
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedProspectAudioGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }
        updateRecentlyPlayedAudios({ ...audio, played: timeStamp });
    };

    const handleRecentlyVideoPlayed = () => {
        const timeStamp = moment().unix().toString();
        let video: Content | Gifts;

        if (videos?.filter((video) => video.nid === contentNid).length > 0) {
            const toSaveVideos: Content[] = videos.map((d) => {
                if (d.nid === contentNid) {
                    video = {
                        ...d,
                        played: timeStamp,
                    };
                    return video;
                } else {
                    return d;
                }
            });
            setGlobalVideos(toSaveVideos);
        } else if (
            receivedMemberVideoGifts?.filter(
                (video) => video.nid === contentNid,
            ).length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] = receivedMemberVideoGifts.map(
                (d) => {
                    if (d.nid === contentNid) {
                        video = {
                            ...d,
                            played: timeStamp,
                        };
                        return video;
                    } else {
                        return d;
                    }
                },
            );
            setGlobalGifts({
                receivedMemberVideoGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectVideoGifts.map((d) => {
                    if (d.nid === contentNid) {
                        video = {
                            ...d,
                            played: timeStamp,
                        };
                        return video;
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedProspectVideoGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }
        updateRecentlyPlayedVideos({ ...video, played: timeStamp });
    };

    const onMediaPlayerReady = useCallback(() => {
        setIsReadyToPlay(true);
        if (!isReady && content) {
            const { skuId, name: title } = content;

            if (type === "audio") {
                const resumeData: ResumeContent = resumeUserAudios
                    ? resumeUserAudios.find((x) => x.nid === contentNid)
                    : ({} as ResumeContent);
                if (
                    resumeData &&
                    Math.ceil(+resumeData.lastPlayed) !==
                        Math.ceil(+resumeData.duration)
                ) {
                    playerRef?.current?.seekTo(
                        resumeData.lastPlayed,
                        "seconds",
                    );
                }
                handleRecentlyAudioPlayed();
                setIsReady(true);
            } else {
                const resumeData: ResumeContent = resumeUserVideos
                    ? resumeUserVideos.find((x) => x.nid === contentNid)
                    : ({} as ResumeContent);
                if (
                    resumeData &&
                    Math.ceil(+resumeData.lastPlayed) !==
                        Math.ceil(+resumeData.duration)
                ) {
                    playerRef?.current?.seekTo(
                        resumeData.lastPlayed,
                        "seconds",
                    );
                }
                const data: ResumeContent = {
                    nid: contentNid,
                    sku_id: skuId,
                    title: title,
                    lastPlayed: playerRef?.current?.getCurrentTime(),
                    maximumPlayed: playerRef?.current?.getCurrentTime(),
                    duration: playerRef?.current?.getDuration(),
                };

                updateResumeVideos(data);
                handleRecentlyVideoPlayed();
                setIsReady(true);
            }
        }
    }, [isReady, content]);

    const onPlayerProgress = async (state: OnProgressProps) => {
        const { skuId, name: title } = content;
        if (state.playedSeconds > 5) {
            const data: ResumeContent = {
                nid: contentNid,
                sku_id: skuId,
                title: title,
                lastPlayed: playerRef?.current?.getCurrentTime(),
                maximumPlayed: playerRef?.current?.getCurrentTime(),
                duration: playerRef?.current?.duration,
            };

            if (type === "audio") {
                const resumeAudioData = resumeUserAudios
                    ? resumeUserAudios.find((x) => x.nid === contentNid)
                    : ({} as ResumeContent);
                if (
                    !resumeAudioData ||
                    state.playedSeconds < +playerRef?.current?.duration - 2 ||
                    (resumeAudioData && !playerRef?.current?.duration)
                ) {
                    updateResumeAudios(data);
                }
            } else if (type === "video") {
                const resumeVideoData = resumeUserVideos
                    ? resumeUserVideos.find((x) => x.nid === contentNid)
                    : ({} as ResumeContent);
                if (
                    !resumeVideoData ||
                    state.playedSeconds < +playerRef?.current?.duration - 2 ||
                    (resumeVideoData && !playerRef?.current?.duration)
                ) {
                    updateResumeVideos(data);
                }
            }
        }
    };

    const handleContentCompleted = () => {
        const { skuId, name: title } = content;
        const data: ResumeContent = {
            nid: contentNid,
            sku_id: skuId,
            title: title,
            lastPlayed: playerRef?.current?.getCurrentTime(),
            maximumPlayed: playerRef?.current?.getCurrentTime(),
            lastCompletedTime: playerRef?.current?.getCurrentTime(),
            duration: playerRef?.current?.getDuration(),
        };
        if (type === "audio") {
            updateResumeAudios({
                ...data,
                lastPlayed: "0",
                maximumPlayed: "0",
            });
        } else {
            updateResumeVideos({
                ...data,
                lastPlayed: "0",
                maximumPlayed: "0",
            });
        }
    };

    const handleRecentlyPlayed = () => {
        if (type === "audio") handleRecentlyAudioPlayed();
        if (type === "video") handleRecentlyVideoPlayed();
    };

    return {
        playerRef,
        isReady,
        setIsReady,
        onMediaPlayerReady,
        onPlayerProgress,
        handleContentCompleted,
        handleRecentlyPlayed,
        isReadyToPlay,
        setIsReadyToPlay,
    };
};

export const useResumeRecentlyBundles = (
    audio: Gifts | ContentWithMedia,
    mediaIndex: number,
    isMini?: boolean,
) => {
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isReadyToPlay, setIsReadyToPlay] = useState<boolean>(false);
    const playerRef = useRef<any>();

    const {
        audios,
        resumeUserBundles,
        setGlobalAudios,
        updateRecentlyPlayed,
        updateResumeBundles,
    } = useGlobalAudios();
    const {
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        setGlobalGifts,
    } = useGlobalGifts();
    const { miniPlayer } = useMiniPlayer();

    const contentId = audio?.nid;
    const media = audio?.media;

    useEffect(() => {
        if (!miniPlayer.url && media && isReady) {
            onMediaPlayerReady(true);
        }
    }, [miniPlayer]);

    const handleRecentlyPlayed = () => {
        const timeStamp = moment().unix().toString();

        if (audios?.filter((a) => a.nid === contentId).length > 0) {
            const toSaveAudios: Content[] = audios?.map((d) => {
                if (d.nid === contentId) {
                    return {
                        ...d,
                        played: timeStamp,
                    };
                } else {
                    return d;
                }
            });

            setGlobalAudios(toSaveAudios);
        } else if (
            receivedMemberAudioGifts?.filter(
                (video) => video?.nid === contentId,
            ).length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] =
                receivedMemberAudioGifts?.map((d) => {
                    if (d?.nid === contentId) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedMemberAudioGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectAudioGifts?.map((d) => {
                    if (d.nid === contentId) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                });

            setGlobalGifts({
                receivedProspectAudioGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }
        updateRecentlyPlayed({ ...audio, played: timeStamp });
    };

    const onMediaPlayerReady = useCallback(
        (must: boolean = false) => {
            const media = audio?.media;
            const timeOutInterval = isMini ? 3000 : 1000;
            setIsReadyToPlay(true);
            if ((!isReady || must) && audio && media) {
                const resumeData: ResumeContent = resumeUserBundles
                    ? resumeUserBundles.find(
                          (x) =>
                              x.nid === audio.nid &&
                              x.title === media[mediaIndex]?.media_title,
                      )
                    : ({} as ResumeContent);
                if (
                    resumeData &&
                    Math.ceil(+resumeData.lastPlayed) !==
                        Math.ceil(+resumeData.duration)
                ) {
                    setTimeout(() => {
                        playerRef?.current?.seekTo(
                            resumeData.lastPlayed,
                            "seconds",
                        );

                        const data: ResumeContent = {
                            nid: audio?.nid,
                            sku_id: audio?.sku_id,
                            title: media[mediaIndex]?.media_title,
                            lastPlayed: resumeData.lastPlayed,
                            maximumPlayed: resumeData.lastPlayed,
                            duration: resumeData.duration,
                        };

                        updateResumeBundles(data);
                    }, timeOutInterval);
                }

                handleRecentlyPlayed();
                setIsReady(true);
            }
        },
        [isReady, audio?.nid, resumeUserBundles, mediaIndex],
    );

    const handleContentCompleted = () => {
        if (media) {
            const data: ResumeContent = {
                nid: audio?.nid,
                sku_id: audio?.sku_id,
                title: media[mediaIndex]?.media_title,
                lastPlayed: "0",
                maximumPlayed: "0",
                lastCompletedTime: playerRef?.current?.getCurrentTime(),
                duration: playerRef?.current?.getDuration(),
            };
            updateResumeBundles(data);
        }
    };

    const onPlayerProgress = async (state: OnProgressProps) => {
        const resumeData: ResumeContent = resumeUserBundles
            ? resumeUserBundles.find(
                  (x) =>
                      x.nid === audio.nid &&
                      x.title === media[mediaIndex]?.media_title,
              )
            : ({} as ResumeContent);
        if (
            audio &&
            media &&
            state.playedSeconds > 5 &&
            (!resumeData ||
                !resumeData.duration ||
                state.playedSeconds < +resumeData.duration - 2)
        ) {
            const data: ResumeContent = {
                nid: audio.nid,
                sku_id: audio.sku_id,
                title: media[mediaIndex]?.media_title,
                lastPlayed: playerRef?.current?.getCurrentTime(),
                maximumPlayed: playerRef?.current?.getCurrentTime(),
            };
            updateResumeBundles(data);
        }
    };

    return {
        playerRef,
        isReady,
        setIsReady,
        onMediaPlayerReady,
        handleRecentlyPlayed,
        handleContentCompleted,
        onPlayerProgress,
        isReadyToPlay,
        setIsReadyToPlay,
    };
};

export const useResumeRecentlyPlaylist = (
    audios: (Gifts | ContentWithMedia)[],
    playlistId: string,
    mediaIndex: number,
    bundleIndex: number,
    isMini?: boolean,
) => {
    const playerRef = useRef<any>();

    const {
        audios: allAudios,
        resumeUserAudios,
        resumeUserBundles,
        updateResumeBundles,
        setGlobalAudios,
        updateRecentlyPlayed,
        updateResumeAudios,
    } = useGlobalAudios();

    const {
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        setGlobalGifts,
    } = useGlobalGifts();

    const { miniPlayer } = useMiniPlayer();

    const [isReady, setIsReady] = useState(false);
    const [isReadyToPlay, setIsReadyToPlay] = useState<boolean>(false);

    const currentAudio = audios ? audios[mediaIndex] : undefined;
    const audio = audios && audios[mediaIndex];

    useEffect(() => {
        if (!miniPlayer.url && currentAudio && isReady) {
            onMediaPlayerReady(true);
        }
    }, [miniPlayer]);

    const { playlistControls } = useGlobalPlaylistControls();

    const { savePlaylistResumeState } = usePlaylistResume(
        playlistId,
        currentAudio?.nid,
        bundleIndex,
    );

    const handleRecentlyPlayed = () => {
        const currentAudio = audios[mediaIndex];

        const timeStamp = moment().unix().toString();

        if (allAudios?.filter((x) => x.nid === currentAudio?.nid).length > 0) {
            const toSaveAudios: Content[] = allAudios.map((d) => {
                if (d.nid === currentAudio?.nid) {
                    return {
                        ...d,
                        played: timeStamp,
                    };
                } else {
                    return d;
                }
            });
            setGlobalAudios(toSaveAudios);
        } else if (
            receivedMemberAudioGifts?.filter(
                (video) => video.nid === currentAudio?.nid,
            ).length > 0
        ) {
            const toSaveReceivedMembers: Gifts[] = receivedMemberAudioGifts.map(
                (d) => {
                    if (d.nid === currentAudio?.nid) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                },
            );
            setGlobalGifts({
                receivedMemberAudioGifts: sortArrayByKey(
                    toSaveReceivedMembers,
                    "title",
                    "ASC",
                ),
            });
        } else {
            const toSaveProspectsMembers: Gifts[] =
                receivedProspectAudioGifts?.map((d) => {
                    if (d.nid === currentAudio?.nid) {
                        return {
                            ...d,
                            played: timeStamp,
                        };
                    } else {
                        return d;
                    }
                });
            setGlobalGifts({
                receivedProspectAudioGifts: sortArrayByKey(
                    toSaveProspectsMembers,
                    "title",
                    "ASC",
                ),
            });
        }
        updateRecentlyPlayed({ ...audio, played: timeStamp });
    };

    const onMediaPlayerReady = useCallback(
        (must: boolean = false) => {
            setIsReadyToPlay(true);
            if (audios && audios[mediaIndex]) {
                const timeOutInterval = isMini ? 3000 : 1000;
                const currentAudio = audios[mediaIndex];
                const isBundle = currentAudio?.media?.length > 1;

                if ((!isReady || must) && currentAudio) {
                    if (isBundle) {
                        const resumeBundleData: ResumeContent =
                            resumeUserBundles
                                ? resumeUserBundles.find(
                                      (x) =>
                                          x.nid === currentAudio.nid &&
                                          x.title ===
                                              currentAudio.media[bundleIndex]
                                                  ?.media_title,
                                  )
                                : ({} as ResumeContent);
                        if (
                            resumeBundleData &&
                            Math.ceil(+resumeBundleData.lastPlayed) !==
                                Math.ceil(+resumeBundleData.duration)
                        ) {
                            setTimeout(() => {
                                playerRef?.current?.seekTo(
                                    resumeBundleData.lastPlayed,
                                    "seconds",
                                );
                                const data: ResumeContent = {
                                    nid: currentAudio.nid,
                                    sku_id: currentAudio.sku_id,
                                    title: isBundle
                                        ? currentAudio?.media[bundleIndex]
                                              ?.media_title
                                        : currentAudio?.title,
                                    lastPlayed: resumeBundleData?.lastPlayed,
                                    maximumPlayed:
                                        resumeBundleData?.maximumPlayed,
                                    duration: resumeBundleData?.duration,
                                };

                                isBundle
                                    ? updateResumeBundles(data)
                                    : updateResumeAudios(data);
                            }, timeOutInterval);
                        }
                    } else {
                        const resumeData: ResumeContent = resumeUserAudios
                            ? resumeUserAudios.find(
                                  (x) => x.nid === currentAudio?.nid,
                              )
                            : ({} as ResumeContent);
                        if (
                            resumeData &&
                            Math.ceil(+resumeData.lastPlayed) !==
                                Math.ceil(+resumeData.duration)
                        ) {
                            setTimeout(() => {
                                playerRef?.current?.seekTo(
                                    resumeData.lastPlayed,
                                    "seconds",
                                );
                                const data: ResumeContent = {
                                    nid: currentAudio?.nid,
                                    sku_id: currentAudio?.sku_id,
                                    title: isBundle
                                        ? currentAudio?.media[bundleIndex]
                                              ?.media_title
                                        : currentAudio?.title,
                                    lastPlayed: resumeData?.lastPlayed,
                                    maximumPlayed: resumeData?.maximumPlayed,
                                    duration: resumeData?.duration,
                                };

                                isBundle
                                    ? updateResumeBundles(data)
                                    : updateResumeAudios(data);
                            }, timeOutInterval);
                        }
                    }

                    handleRecentlyPlayed();
                    setIsReady(true);
                }
            }
        },
        [
            isReady,
            resumeUserBundles,
            resumeUserAudios,
            audios,
            mediaIndex,
            bundleIndex,
        ],
    );

    const onPlayerProgress = async (state: OnProgressProps) => {
        if (state.playedSeconds > 5) {
            updateResumeData({
                lastPlayed: playerRef.current.getCurrentTime(),
                maximumPlayed: playerRef.current.getCurrentTime(),
            });
        }
    };

    const updateResumeData = (playedTime: {
        lastPlayed: string;
        maximumPlayed: string;
    }) => {
        const currentAudio = audios[mediaIndex];
        if (currentAudio) {
            const isBundle = currentAudio.media.length > 1;
            const data: ResumeContent = {
                nid: currentAudio.nid,
                sku_id: currentAudio.sku_id,
                title: isBundle
                    ? currentAudio?.media[bundleIndex]?.media_title
                    : currentAudio.title,
                lastPlayed: playedTime.lastPlayed,
                maximumPlayed: playedTime.maximumPlayed,
            };
            isBundle ? updateResumeBundles(data) : updateResumeAudios(data);
        }
    };

    const handleContentCompleted = () => {
        updateResumeData({
            lastPlayed: "0",
            maximumPlayed: "0",
        });
        if (!playlistControls.loop && mediaIndex === audios?.length - 1)
            savePlaylistResumeState({
                id: playlistId,
                resumeAudioNid: currentAudio?.nid,
                resumeBundleIndex: 0,
            });
    };

    return {
        playerRef,
        isReady,
        setIsReady,
        onMediaPlayerReady,
        onPlayerProgress,
        handleContentCompleted,
        handleRecentlyPlayed,
        isReadyToPlay,
        setIsReadyToPlay,
    };
};
