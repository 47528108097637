import React, { useCallback, useEffect, useMemo, useState } from "react";
import qs from "qs";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, Grid, InternalStandardProps, Theme } from "@mui/material";
import { Layout } from "../Layout";
import { AppHeader } from "JS/React/Components/AppHeader";
import {
    useActiveCourse,
    useCourses,
    useGlobalCourses,
    useReceivedCourses,
} from "JS/React/Hooks/Course/Course";
import { useRouting } from "JS/React/Hooks/Routes";
import { useHistory, useLocation } from "react-router-dom";
import { ActiveCourseType } from "JS/Redux/Course";
import { Course, ReceivedCourse } from "JS/Models";
import { HomeSegment } from "JS/React/Hooks/NavigationEngine";
import { AppNoDataFound } from "JS/React/Components/AppNoDataFound";
import { messaging } from "JS/Helpers/UserMessaging";
import { useScrollToTop } from "JS/React/Hooks/Media";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useDownloadedCourses } from "JS/React/Hooks/DownloadCourses";
import { ListingComponent } from "../Audios/ListingComponent";

export const Courses = (props: CoursesProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const { courses: allCourses, isCourseUpdateRequired } = useGlobalCourses();
    const { courses, loading: coursesLoading } = useCourses(
        !allCourses || allCourses.length === 0,
        isCourseUpdateRequired,
    );
    const { setActiveCourse } = useActiveCourse();
    useSeamlessUpdate();
    const { receivedCourses, loading: receivedCoursesLoading } =
        useReceivedCourses();

    const { linkProvider } = useRouting();
    const history = useHistory();
    const location = useLocation();
    const { downloadedCourses, checkIsCrsDownloaded } = useDownloadStatus(
        courses,
        receivedCourses,
    );

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { segment = "" } = parsedQueryString;

        return {
            segment: segment.toString(),
        };
    }, [location?.search]);
    const { coursesStack, setGlobalCoursesStack, homeStack } =
        useGlobalNavStack();

    const [searchQuery, setSearchQuery] = useState(coursesStack?.searchQuery);
    const paramTab = useMemo(() => {
        if (queryParams.segment === HomeSegment.MY) {
            return Tabs.MY_COURSES;
        }
        if (queryParams.segment === HomeSegment.SAVED) {
            return Tabs.SAVED;
        }

        return Tabs.ALL_COURSES;
    }, [queryParams.segment]);

    const [selectedTab, setSelectedTab] = useState(paramTab);

    const filteredData = useMemo(() => {
        if (selectedTab === Tabs.ALL_COURSES) return courses;
        if (selectedTab === Tabs.MY_COURSES) {
            return [
                ...courses.filter((d) => d.is_purchased || d.publish_free),
                ...receivedCourses,
            ];
        }
        if (selectedTab === Tabs.SAVED)
            return downloadedCourses ? downloadedCourses.savedCourses : [];
    }, [courses, receivedCourses, selectedTab]);

    const searchResults = useMemo(() => {
        return filteredData?.filter(
            (d) =>
                d.title
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()) ||
                d.description
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()),
        );
    }, [filteredData, searchQuery]);

    const setQueryParams = (params: Partial<typeof queryParams>) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify({ ...queryParams, ...params }),
        });
    };

    const getParamsSegment = (activeTab: string) => {
        if (activeTab === Tabs.MY_COURSES) {
            return HomeSegment.MY;
        }
        if (activeTab === Tabs.SAVED) {
            return HomeSegment.SAVED;
        }
        return HomeSegment.ALL;
    };

    const setQueryParamsTab = (tab) => {
        setSelectedTab(tab);
        setQueryParams({
            segment: getParamsSegment(tab),
        });
    };

    useEffect(() => {
        setGlobalCoursesStack({
            searchQuery: searchQuery,
        });
    }, [searchResults]);

    useScrollToTop();

    const loading = coursesLoading || receivedCoursesLoading;

    useEffect(() => {
        if (coursesStack?.searchQuery !== "") {
            window.scrollTo(0, 500);
            window.scrollTo(0, 0);
        }
    }, []);
    const handleListItemClick = (
        data: Course | ReceivedCourse,
        _,
        scrollIndex: number = 0,
    ) => {
        if (data.isReceived) {
            setActiveCourse({
                type: ActiveCourseType.RECEIVED,
                course: data as ReceivedCourse,
            });
        } else {
            setActiveCourse({
                type: ActiveCourseType.UNRECIEVED,
                course: data as Course,
            });
        }

        history.push(
            linkProvider.react.courses().detail(data.nid, {
                received: data.isReceived ? "true" : "false",
                segment: getParamsSegment(selectedTab),
                scrollTo: scrollIndex,
            }),
        );
    };

    return (
        <Layout>
            <div {...rest} className={clsx(className, classes.root)}>
                <AppHeader
                    title="Courses"
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Courses by title/description"}
                    canGoBack={homeStack?.isHomeTrack}
                    onBackClick={() => {
                        if (homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                <Box display={"flex"} justifyContent={"space-around"}>
                    {Object.values(Tabs).map((tab) => (
                        <span
                            key={tab}
                            onClick={() => setQueryParamsTab(tab)}
                            className={clsx(
                                classes.tab,
                                selectedTab === tab && classes.grey600,
                            )}
                        >
                            {tab}
                        </span>
                    ))}
                </Box>
                {searchResults && !loading && searchResults.length > 0 ? (
                    <ListingComponent
                        listContent={searchResults}
                        searchQuery={searchQuery}
                        showSortHeader={false}
                        setGlobalStack={setGlobalCoursesStack}
                        globalStack={coursesStack}
                        handleListItemClick={handleListItemClick}
                        controlType="course"
                        shouldSort={false}
                        checkIsCrsDownloaded={checkIsCrsDownloaded}
                    />
                ) : (
                    <Grid
                        className={classes.wrapperLoader}
                        item
                        xs={12}
                        md={12}
                    >
                        <AppNoDataFound
                            notFoundText={messaging?.common?.noDataFound}
                        />
                    </Grid>
                )}

                {loading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.courses}
                    />
                )}
            </div>
        </Layout>
    );
};

const useDownloadStatus = (
    courses: Course[],
    receivedCourses: ReceivedCourse[],
) => {
    const { userDownloadedCourses } = useDownloadedCourses();

    const getUserDownloadedCourse = useCallback(
        (course: Course[], received: ReceivedCourse[]) => {
            let downloadedIds = [];
            userDownloadedCourses.forEach((x) => {
                const allDownloaded = x.steps.every((s) => s.isDownloaded);
                if (allDownloaded) downloadedIds.push(x.nid);
            });

            return [...course, ...received].filter((x) =>
                downloadedIds.includes(x.nid),
            );
        },
        [userDownloadedCourses],
    );

    const downloadedCourses = useMemo(() => {
        const crs = getUserDownloadedCourse(courses, receivedCourses);
        return {
            savedCourses: crs,
            savedCourseIds: crs.map((x) => x.nid),
        };
    }, [userDownloadedCourses, courses, receivedCourses]);

    const checkIsCrsDownloaded = (
        c: Course | ReceivedCourse,
    ): Promise<boolean> => {
        return new Promise((res) => {
            if (downloadedCourses.savedCourseIds.includes(c.nid)) {
                res(true);
            } else {
                res(false);
            }
        });
    };

    return {
        downloadedCourses,
        checkIsCrsDownloaded,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        tab: {
            cursor: "pointer",
            fontSize: "1rem",
            color: theme.palette.grey[300],
            fontWeight: 500,

            "&:hover": {
                color: theme.palette.grey[600],
            },
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - (${theme.footer.height} + ${theme.header.height}))`,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.common.black,
        },
    }),
);

export interface CoursesProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

enum Tabs {
    ALL_COURSES = "All Courses",
    MY_COURSES = "My Courses",
    SAVED = "Saved",
}
