import { AudioDexie, VideoDexie } from "JS/Database/Dexie";
import { Content, ContentWithMedia } from "../Audio";
import { Course, ReceivedCourse } from "../Courses";
import { Gifts } from "../Media";
import { OfflineBundle } from "../Common";

export interface SentGift {
    nid: string;
    sku: string;
    contentTitle: string;
    receiverFirstName: string;
    receiverLastName: string;
}

export interface SentGiftToLog extends SentGift {
    createdAt: number;
}

export enum SentGiftType {
    AUDIO = "Audio",
    VIDEO = "Video",
    COURSE = "Course",
}

export enum SentGiftAction {
    OPEN = "Opened",
    PLAYING = "Playing",
    COMPLETED = "Completed",
}

export interface ActionDetails {
    duration?: number;
    stepSku?: string;
    stepName?: string;
    index?: number;
}

export interface DashboardCache {
    gift: SentGiftToLog;
    type: SentGiftType;
    memberId: Gifts["sender"]["member_id"];
    actionDetails?: ActionDetails;
}

export interface PushStatus {
    shouldSendPush?: boolean;
}

export interface DashboardActivity {
    createdAt: number;
    nid: string;
    sku: string;
    receiverId: string;
    receiverFirstName: string;
    receiverLastName: string;
    contentType: string;
    contentTitle: string;
    activity: {
        action: string;
        actionDetails?: ActionDetails;
    };
}

export interface DashboardLogs {
    Activity: DashboardActivity[];
}

export interface PushLog {
    sku: string;
    action: string;
    index?: number;
}

export interface PushLogs {
    [key: string]: PushLog[];
}

export const toSentGiftType = (type: string) => {
    if (!type) return undefined;
    const l = type.toLowerCase();
    if (l.includes("mp3") || l.includes("audio")) return SentGiftType.AUDIO;
    else if (l.includes("video")) return SentGiftType.VIDEO;
    else if (l.includes("course")) return SentGiftType.COURSE;
};

export const toSentGift = (
    content: ContentWithMedia | Content | Gifts | Course | ReceivedCourse,
    mediaIndex?: number,
) => {
    if (!content) return undefined;

    //for handling bundle title
    const audMedia = (content as ContentWithMedia)?.media;
    const isBundle = audMedia && audMedia?.length > 1;
    const bundleTitle =
        !!audMedia && mediaIndex != null
            ? audMedia[mediaIndex]?.media_title
            : "";

    return {
        nid: content.nid,
        sku: content.sku_id,
        contentTitle:
            isBundle && mediaIndex != null ? bundleTitle : content.title,
    } as SentGift;
};

export const toSentOfflineGift = (
    content: AudioDexie | VideoDexie | OfflineBundle,
    mediaIndex?: number,
) => {
    if (!content) return undefined;

    //for handling bundle title
    const audMedia = (content as OfflineBundle)?.media;
    const isBundle = audMedia && audMedia?.length > 1;
    const bundleTitle =
        !!audMedia && mediaIndex != null
            ? audMedia[mediaIndex]?.media_title
            : "";

    return {
        nid: content?.id?.split("-")[1],
        sku: content.skuId,
        contentTitle:
            isBundle && mediaIndex != null ? bundleTitle : content.name,
    } as SentGift;
};

export const getPlayerPlayedTimeToLog = (
    playerRef: any,
    mediaIndex?: number,
) => {
    const data: ActionDetails = {
        duration: +playerRef?.current?.getCurrentTime()?.toFixed(0),
    };
    if (mediaIndex) data.index = mediaIndex;
    return data;
};

export const getPlayerPlayedTimeForBundle = (
    playerRef: any,
    mediaIndex: number,
) => {
    return {
        duration: +playerRef?.current?.getCurrentTime()?.toFixed(0),
        index: mediaIndex,
    };
};
