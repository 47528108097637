import numeral from "numeral";
import { appConstants } from "./Contants";
import { AllTabs, Content, Course, EventCategories, Gifts } from "JS/Models";
import {
    getLoggedInUserMigrated,
    setLoggedInUserMigrated,
} from "./LocalStorageHelpers";
import { generateRandomNumber } from "./Number";

export const toNumber = (a: any) => {
    return numeral(a).value();
};

export const getUniqueValues = (array: any, key: string) => {
    return array.reduce((acc, current) => {
        const x = acc.find((item) => item[key] === current[key]);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
};

export const sortArrayByKey = <T>(
    array: T[],
    key: string,
    dir: string,
): T[] => {
    if (array && array.length > 0) {
        if (dir === "ASC") {
            return array.sort((a, b) => a[key].localeCompare(b[key]));
        } else {
            return array.sort((a, b) => b[key].localeCompare(a[key]));
        }
    } else {
        return [];
    }
};

export const sortArrayByNumberKey = <T>(
    array: T[],
    key: string,
    dir: string,
): T[] => {
    if (array && array.length > 0) {
        if (dir === "ASC") {
            return array.sort((a, b) => a[key] - b[key]);
        } else {
            return array.sort((a, b) => b[key] - a[key]);
        }
    } else {
        return [];
    }
};

export const asyncSortArrayByNumberKey = async <T>(
    array: T[],
    key: string,
    dir: string,
): Promise<T[]> => {
    if (array && array.length > 0) {
        if (dir === "ASC") {
            return array.sort((a, b) => a[key] - b[key]);
        } else {
            return array.sort((a, b) => b[key] - a[key]);
        }
    } else {
        return [];
    }
};

export const sortArrayByDate = (array: any, key: string, dir: string) => {
    if (array && array.length > 0) {
        if (dir === "ASC") {
            return array.sort((a, b) =>
                new Date(Number(a[key]) * 1000)
                    .getTime()
                    .toLocaleString()
                    .localeCompare(
                        new Date(Number(b[key]) * 1000)
                            .getTime()
                            .toLocaleString(),
                    ),
            );
        } else {
            return array.sort((a, b) =>
                new Date(Number(b[key]) * 1000)
                    .getTime()
                    .toLocaleString()
                    .localeCompare(
                        new Date(Number(a[key]) * 1000)
                            .getTime()
                            .toLocaleString(),
                    ),
            );
        }
    } else {
        return [];
    }
};

export const calculatePercentage = (completed: number, total: number) =>
    completed >= 0 && total > 0 ? (completed / total) * 100 : 0;

//This method should be used for clearing local storage instead of the localStorage.clear one
export const clearLocalStorage = () => {
    const webcastUUId = localStorage.getItem(
        appConstants.localStorage.webcastUUID,
    );
    const isMigrated = localStorage.getItem("playedMigrated");
    const themeMode = localStorage.getItem("themeMode");
    const playlistMig = localStorage.getItem(
        appConstants.localStorage.playlistMigrated,
    );
    const loggedInUserMig = getLoggedInUserMigrated();

    localStorage.clear();
    localStorage.setItem("playedMigrated", isMigrated);
    localStorage.setItem("themeMode", themeMode);
    localStorage.setItem(
        appConstants.localStorage.playlistMigrated,
        playlistMig,
    );
    setLoggedInUserMigrated(loggedInUserMig);
    if (webcastUUId !== null)
        localStorage.setItem(
            appConstants.localStorage.webcastUUID,
            webcastUUId,
        );
};
export const getFileExtenstionFromName = (fileName: string) => {
    return (
        fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
        fileName
    );
};

export const dataURLtoFile = async (dataUrl: any, fileName: string) => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], `${fileName}.jpg`, { type: "image/jpg" });
};

export function shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length,
        randomIndex: number;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(generateRandomNumber() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex],
        ];
    }

    return array;
}

export function getGiftCategory(selectedTab: string) {
    if (selectedTab === AllTabs.AUDIOS) return EventCategories.GIFT_AUDIOS;
    else if (selectedTab === AllTabs.VIDEOS) return EventCategories.GIFT_VIDEOS;
    else if (selectedTab === AllTabs.COURSES)
        return EventCategories.GIFT_COURSE;
}

export const isRedeemable = (
    content: Content | Gifts | Course,
    availableCredits: number,
    contentPrice?: string,
) => {
    const price = +contentPrice || +content?.content_price || 0;
    return (
        price === 0 ||
        ((content?.is_redeemable_by_tokens === "1" ||
            content?.is_redeemable_by_tokens) &&
            availableCredits !== 0 &&
            availableCredits >= +content?.number_of_tokens)
    );
};
