import { isSupported } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth, signInAnonymously } from "firebase/auth";
import { Database, getDatabase, ref, set } from "firebase/database";
import { config } from "JS/Config";
import {
    customNameToAction,
    CustomCommonRealtimeLogs,
    CustomReatimeEventName,
    CustomRealtimeLogs,
} from "JS/Models";
import moment from "moment";
import { getTimezone } from "JS/Helpers";

class LoggingService {
    private app: FirebaseApp;
    private db: Database;
    private auth: Auth;

    constructor() {
        isSupported().then((supported) => {
            if (supported) {
                this.app = initializeApp(
                    config.firebase.logging,
                    "RMAS-Logging",
                );
                this.db = getDatabase(this.app);
                this.auth = getAuth(this.app);
            }
        });
    }

    async signInAnonymously() {
        await signInAnonymously(this.auth);
    }

    async logRealtimeAnalysis(
        name: CustomReatimeEventName | string,
        stats?: Partial<CustomRealtimeLogs> | any,
        action?: string,
    ) {
        const currentMoment = moment();
        const currentDate = currentMoment.format("YYYY-MM-DD");
        const currMiliSecs = currentMoment.valueOf();

        const iboId = config.user.memberId;
        const appVersion = config.appVersion;
        const platform = "React-Media";

        const url = `${platform}/${currentDate}/${iboId}/${currMiliSecs}-${name}`;

        const common: CustomCommonRealtimeLogs = {
            appVersion,
            iboId,
            action: action ? action : customNameToAction[name],
            category: "General",
            marketAlias: "LTD",
            platform: platform,
            ts: `${currMiliSecs}`,
            tz: getTimezone(),
        };

        const statsToLog = stats
            ? {
                  ...stats,
                  ...common,
              }
            : common;

        if (shouldLog()) {
            try {
                const dbRef = ref(this.db, url);
                await set(dbRef, statsToLog);
                console.log("logs sent!");
            } catch (err) {
                console.log("Error in sending logs!", err);
            }
        }
    }
}

const shouldLog = () => false;

export const loggingFirebaseService = new LoggingService();
