import { Content, Course, Gifts, ReceivedCourse } from "JS/Models";
import { Sorting } from "JS/Types";
import {
    AudioDownloadProps,
    VideoDownloadProps,
} from "../Scenes/Audios/ListingComponent";
import {
    AppContentListItem,
    AppContentListItemType,
} from "./AppContentListItem";
import { CSSProperties } from "react";
import { useAppSelector } from "JS/Redux/Store";
import { isInDownloadQueue } from "JS/Helpers/ContentDownloadHelper";
import { config } from "JS/Config";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { useGestureToScroll } from "../Hooks/Common/GestureControl";
import { nidControlTypes } from "JS/Helpers/Contants";
export interface Thumbnails {
    [key: string]: string;
}
export interface AppContentRowProps {
    index: number;
    style: CSSProperties;
    listData: Gifts | Content | Course | ReceivedCourse;
    sorting: Sorting;
    thumbnails: Thumbnails;
    controlType: string;
    isFavouriteContent: (filteredContent: Content | Gifts) => boolean;
    isResumeable: (
        content: Content | Gifts | Course | ReceivedCourse,
    ) => boolean;
    getDownloadStatus?: (
        content: Content | Gifts | Course | ReceivedCourse,
    ) => VideoDownloadProps | AudioDownloadProps;
    handleListItemClick: (
        content: Content | Gifts | Course | ReceivedCourse,
        isAutoPlay: boolean,
        scrollIndex?: number | string,
    ) => void;
    selectMode?: boolean;
    isSelected?: boolean;
    onGiftCheckChanged?: (nid: string, checked: boolean) => void;
    contentType?: AppContentListItemType;
    checkIsCrsDownloaded?: (
        content: Course | ReceivedCourse,
    ) => Promise<boolean>;
    isAllowClick?: boolean;
    isDisabled?: boolean;
    handleToogleCheckBox?: (nid: string, value: boolean) => void;
    isCheckedItem?: boolean;
    isFromSpeakerPlaylist?: boolean;
}
export const AppContentRow = ({
    index,
    style,
    listData,
    contentType,
    sorting,
    thumbnails,
    handleListItemClick,
    getDownloadStatus,
    controlType,
    isResumeable,
    isFavouriteContent,
    selectMode = false,
    isSelected = false,
    onGiftCheckChanged,
    checkIsCrsDownloaded,
    isAllowClick,
    isDisabled,
    handleToogleCheckBox,
    isCheckedItem = false,
    isFromSpeakerPlaylist,
}: AppContentRowProps) => {
    const downloadStatus = () => getDownloadStatus(listData);
    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );
    const { addScrollIndexInListingUrl } = useGestureToScroll();
    const checkFavouriteDownloadProgress = (filteredContent: Content) => {
        return inProgressNid === filteredContent.nid;
    };
    const checkFavouriteDownloadQueue = (filteredContent: Content) => {
        return isInDownloadQueue(filteredContent.nid);
    };
    const checkDownloadProgress = (
        downloadStatus: VideoDownloadProps | AudioDownloadProps,
    ) => {
        if (controlType === "video") {
            return (downloadStatus as VideoDownloadProps)?.isDownloadInProgress;
        }
        if (controlType === "audio") {
            return (
                (downloadStatus as AudioDownloadProps)?.isDownloading &&
                !downloadStatus?.inProgressDownloaded
            );
        }
        return false;
    };
    const checkFavouriteInProgressDownloaded = (filteredAudio: Content) => {
        return downloadedContentNids?.includes(
            `${config.user.memberId}-${filteredAudio.nid}`,
        );
    };
    const checkFavouriteIsDownloaded = async (filteredContent: Content) => {
        if (controlType === "videoFavorite") {
            const videoResponse = await getDexieConnectionRefresh().videos.get(
                `${config.user.memberId}-${filteredContent.nid}`,
            );
            return videoResponse ? true : false;
        } else if (controlType === "audioFavorite") {
            const audioResponse = await getDexieConnectionRefresh().audios.get(
                `${config.user.memberId}-${filteredContent.nid}`,
            );
            return audioResponse ? true : false;
        }
        return false;
    };

    const getDownloadProps = () => {
        if (
            ["audio", "video", "audioFavorite", "videoFavorite"].includes(
                controlType,
            )
        ) {
            const isFavorite = controlType.includes("Favorite");
            const downloadStatus = () =>
                getDownloadStatus(listData as Content | Gifts);
            return {
                isDownloadInProgress: isFavorite
                    ? checkFavouriteDownloadProgress(listData as Content)
                    : checkDownloadProgress(downloadStatus()),
                isInDownloadQueue: isFavorite
                    ? checkFavouriteDownloadQueue(listData as Content)
                    : checkDownloadQueue(downloadStatus()),
                isDownloaded: isFavorite
                    ? checkFavouriteIsDownloaded(listData as Content)
                    : checkIsDownloaded(downloadStatus()),
                inProgressDownloaded: isFavorite
                    ? checkFavouriteInProgressDownloaded(listData as Content)
                    : downloadStatus()?.inProgressDownloaded,
            };
        }
        return {};
    };
    const checkDownloadQueue = (
        downloadStatus: VideoDownloadProps | AudioDownloadProps,
    ) => {
        if (controlType === "video") {
            return (downloadStatus as VideoDownloadProps)?.isInDownloadQueue;
        }
        if (controlType === "audio") {
            return (
                (downloadStatus as AudioDownloadProps)?.isInDownloadedQueue &&
                !(downloadStatus as AudioDownloadProps)?.isDownloading
            );
        }
        return false;
    };
    const checkIsDownloaded = (
        downloadStatus: VideoDownloadProps | AudioDownloadProps,
    ) => {
        if (controlType === "video") {
            return (downloadStatus as VideoDownloadProps)?.isDownloaded;
        }
        if (controlType === "audio") {
            return (downloadStatus as AudioDownloadProps)?.isAudioDownloaded;
        }
        return Promise.resolve(false);
    };
    const getCourseProps = () => ({
        publishFree: (listData as Course)?.publish_free,
        isPurchased: (listData as Course)?.is_purchased,
        isRedeemableByToken: (listData as Course)?.is_redeemable_by_tokens
            ? "1"
            : "0",
        contentPrice: (listData as Course)?.content_price,
        creditsRequired: (listData as Course)?.number_of_tokens,
        numberOfTokens: (listData as Course)?.number_of_tokens?.toString(),
        isReceived: listData.isReceived,
        ...(controlType === "course" && {
            isDownloaded: checkIsCrsDownloaded
                ? checkIsCrsDownloaded(listData as Course)
                : Promise.resolve(false),
        }),
    });
    const getPlayIconProps = () => {
        const showPlayIcon =
            controlType !== "course" &&
            ("is_purchased" in listData ||
                "isReceived" in listData ||
                "publish_free" in listData) &&
            ((listData as Content | Gifts).is_purchased ||
                (listData as Content | Gifts).isReceived ||
                (listData as Content | Gifts).publish_free === "1");
        const playedProp =
            controlType !== "course" && "played" in listData
                ? { played: listData.played }
                : {};
        return {
            showPlayIcon: showPlayIcon,
            ...playedProp,
        };
    };
    const getFavoriteAndResumeProps = () => {
        const props: {
            isResume?: boolean;
            showFavIcon?: boolean;
            isFavourite?: boolean;
        } = {};
        if (typeof isResumeable === "function") {
            props.isResume = isResumeable(listData as Content | Gifts);
        }
        if (typeof isFavouriteContent === "function") {
            const isFavourite = isFavouriteContent(listData as Content | Gifts);
            props.showFavIcon = isFavourite;
            props.isFavourite = isFavourite;
        }
        return props;
    };
    const getPlayedProps = () => {
        if (controlType !== "course" && "played" in listData) {
            return { played: listData.played };
        }
        return {};
    };
    return (
        <AppContentListItem
            style={style}
            nid={listData?.nid}
            isDownloadInProgress={
                (controlType === "audio" || controlType === "video") ??
                checkDownloadProgress(downloadStatus())
            }
            isInDownloadQueue={
                (controlType === "audio" || controlType === "video") ??
                checkDownloadQueue(downloadStatus())
            }
            isDownloaded={
                controlType === "audio" || controlType === "video"
                    ? checkIsDownloaded(downloadStatus())
                    : Promise.resolve(false)
            }
            inProgressDownloaded={
                (controlType === "audio" || controlType === "video") ??
                downloadStatus()?.inProgressDownloaded
            }
            type={contentType || "media"}
            key={index}
            sender={(listData as any).sender}
            isReceived={listData.isReceived}
            title={listData.title}
            skuId={listData.sku_id}
            attachedSku={
                "attach_skus" in listData
                    ? listData?.attach_skus
                    : {
                          member_sku_id: (listData as Content)?.member_gift_sku,
                          prospect_sku_id: (listData as Content)
                              ?.prospect_gift_sku,
                      }
            }
            description={listData.description}
            releaseDate={listData.release_date}
            acquiredDate={listData.acquired_date}
            sortType={!!sorting ? sorting[0].col : undefined}
            selectMode={selectMode}
            imageUrl={thumbnails && thumbnails[listData.nid]}
            isAllowClick={isAllowClick ?? true}
            disabled={!!isDisabled}
            onListItemClick={() => {
                addScrollIndexInListingUrl(
                    nidControlTypes.includes(controlType)
                        ? +listData?.nid
                        : index,
                );
                handleListItemClick(
                    listData,
                    true,
                    nidControlTypes.includes(controlType)
                        ? +listData?.nid
                        : index,
                );
            }}
            handleToogleCheckBox={handleToogleCheckBox}
            onItemPlayClick={() => {
                addScrollIndexInListingUrl(
                    nidControlTypes.includes(controlType)
                        ? +listData?.nid
                        : index,
                );
                handleListItemClick(
                    listData,
                    true,
                    nidControlTypes.includes(controlType)
                        ? +listData?.nid
                        : index,
                );
            }}
            giftChecked={isSelected}
            onGiftCheckChanged={onGiftCheckChanged}
            isFromSpeakerPlaylist={isFromSpeakerPlaylist}
            isCheckedItem={isCheckedItem}
            {...getPlayIconProps()}
            {...getPlayedProps()}
            {...getFavoriteAndResumeProps()}
            {...getDownloadProps()}
            {...getCourseProps()}
        />
    );
};
