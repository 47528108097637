import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FavoritesState {
    favNids: string[];
}

const initialState: FavoritesState = {
    favNids: [],
};

export const favoritesStateSlice = createSlice({
    name: "favoritesState",
    initialState,
    reducers: {
        setGlobalFavNids: (state, data: PayloadAction<string[]>) => {
            state.favNids = data.payload;
            return state;
        },
        setMarkFavNid: (state, data: PayloadAction<string>) => {
            state.favNids = [...state.favNids, data.payload];
            return state;
        },
        setUnmarkFavNid: (state, data: PayloadAction<string>) => {
            state.favNids = state.favNids.filter((nid) => nid !== data.payload);
            return state;
        },
        resetFavoritesState: () => {
            return initialState;
        },
    },
});
export const {
    setGlobalFavNids,
    setMarkFavNid,
    setUnmarkFavNid,
    resetFavoritesState,
} = favoritesStateSlice.actions;
