import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    BoxProps,
    InternalStandardProps,
    List,
    ListItem,
    Theme,
} from "@mui/material";
import { Layout } from "../Layout";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppHeader } from "JS/React/Components/AppHeader";
import { favoritesCategoriesToString } from "JS/Helpers";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import React, { useMemo } from "react";
import { useGlobalVideos, useVideos } from "JS/React/Hooks/Video";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { uniqBy } from "lodash-es";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
    useReceivedMemberVideoGifts,
    useReceivedProspectAudioGifts,
    useReceivedProspectVideoGifts,
} from "JS/React/Hooks/Gifts";
import { messaging } from "JS/Helpers/UserMessaging";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { useGlobalDocuments } from "JS/React/Hooks/Documents";
import { AppDivider } from "JS/React/Components/AppDivider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        favTopText: {
            color: theme.palette.grey[600],
        },
        listItem: {
            fontWeight: 400,
            display: "flex",
            justifyContent: "flex-start",
        },
        listText: { fontWeight: 400 },
        list: {
            flexGrow: 1,
        },
        listIcon: {
            fontWeight: 400,
            fontSize: "24px",
            marginRight: "5%",
            width: "10%",
        },
    }),
);

export enum FavoritesCategories {
    AUDIOS = "Audios",
    VIDEOS = "Videos",
    DOCUMENTS = "Documents",
}

export enum FavoritesTypes {
    FAVAUDIOS = "favAudios",
    FAVVIDEOS = "favVideos",
}

type IconType = [IconPrefix, IconName];

export interface FavoritesProps extends InternalStandardProps<BoxProps> {}

export const Favorites = (props: FavoritesProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const { audios, isAudioUpdateRequired } = useGlobalAudios();
    const { videos, isVideoUpdateRequired } = useGlobalVideos();
    const { resetFavoritesStack, homeStack } = useGlobalNavStack();

    const { loading: audiosLoading } = useAudios(
        !!audios && audios.length > 0,
        isAudioUpdateRequired,
    );

    const { loading: videosLoading } = useVideos(
        !!videos && videos.length > 0,
        isVideoUpdateRequired,
    );

    const {
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        receivedMemberVideoGifts,
        receivedProspectVideoGifts,
    } = useGlobalGifts();

    const { loading: receivedMemberVideoGiftsLoading } =
        useReceivedMemberVideoGifts(
            !!receivedMemberVideoGifts && receivedMemberVideoGifts.length > 0,
        );

    const { loading: receivedProspectVideoGiftsLoading } =
        useReceivedProspectVideoGifts(
            !!receivedProspectVideoGifts &&
                receivedProspectVideoGifts.length > 0,
        );

    const { loading: receivedMemberAudioGiftsLoading } =
        useReceivedMemberAudioGifts(
            !!receivedMemberAudioGifts && receivedMemberAudioGifts.length > 0,
        );

    const { loading: receivedProspectAudioGiftsLoading } =
        useReceivedProspectAudioGifts(
            !!receivedProspectAudioGifts &&
                receivedProspectAudioGifts.length > 0,
        );

    const { loading: favLoading } = useGetFavorites();
    const { favNids } = useGlobalFavorites();

    const { documentUserFavorites } = useGlobalDocuments();

    const filteredAudios = useMemo(() => {
        return uniqBy(
            [
                ...audios,
                ...receivedMemberAudioGifts,
                ...receivedProspectAudioGifts,
            ],
            (x) => x.nid,
        );
    }, [audios, receivedMemberAudioGifts, receivedProspectAudioGifts]);

    const filteredVideos = useMemo(() => {
        return uniqBy(
            [
                ...videos,
                ...receivedMemberVideoGifts,
                ...receivedProspectVideoGifts,
            ],
            (x) => x.nid,
        );
    }, [audios, receivedMemberVideoGifts, receivedProspectVideoGifts]);

    const filterFavoritesAudios = useMemo(
        () => filteredAudios.filter((audio) => favNids?.includes(audio.nid)),
        [filteredAudios, favNids],
    );

    const filterFavoritesVideos = useMemo(
        () => filteredVideos.filter((video) => favNids?.includes(video.nid)),
        [filteredVideos, favNids],
    );

    const audiosContentLoaded =
        !audiosLoading &&
        !receivedMemberAudioGiftsLoading &&
        !receivedProspectAudioGiftsLoading;

    const videosContentLoaded =
        !videosLoading &&
        !receivedMemberVideoGiftsLoading &&
        !receivedProspectVideoGiftsLoading;

    const favoritesContentLoaded =
        !favLoading || (favNids?.length !== 0 && favNids?.length !== 0);

    const handleItemClick = (item) => {
        if (item === FavoritesCategories.AUDIOS)
            history.push(
                linkProvider.react.favorites().favoriteAudiosListing(),
            );
        else if (item === FavoritesCategories.VIDEOS)
            history.push(
                linkProvider.react.favorites().favoriteVideosListing(),
            );
        else
            history.push(
                linkProvider.react.favorites().favoriteDocumentsListing(),
            );
    };

    const getListItemIcons = (item) => {
        if (item === FavoritesCategories.VIDEOS) {
            return ["fas", "video"] as IconType;
        } else if (item === FavoritesCategories.AUDIOS) {
            return ["fas", "headphones"] as IconType;
        } else {
            return ["far", "clipboard"] as IconType;
        }
    };

    const history = useHistory();
    const { linkProvider } = useRouting();

    return (
        <Layout>
            <Box {...rest} className={clsx(className, classes.root)}>
                <AppHeader
                    title={"Favorites"}
                    canGoBack
                    onBackClick={() => {
                        if (!homeStack?.isHomeTrack) {
                            resetFavoritesStack();
                            history.push(linkProvider.react.more().index());
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                    searchable={false}
                />
                <Box>
                    <AppTypography
                        variant="body2"
                        textAlign={"center"}
                        className={classes.favTopText}
                    >
                        You can mark your favorite media and have quick access
                        from this screen.
                    </AppTypography>
                </Box>
                <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                    <List className={classes.list}>
                        {Object.values(FavoritesCategories).map((item) => (
                            <React.Fragment key={item}>
                                <ListItem
                                    button
                                    className={clsx(classes.listItem)}
                                    onClick={() => handleItemClick(item)}
                                >
                                    <FontAwesomeIcon
                                        className={clsx(
                                            classes.grey600,
                                            classes.listIcon,
                                        )}
                                        icon={getListItemIcons(item)}
                                    />
                                    <AppTypography
                                        className={clsx(
                                            classes.grey600,
                                            classes.listText,
                                        )}
                                        variant="h6"
                                    >
                                        {favoritesCategoriesToString(item)}
                                        &nbsp;
                                        {((videosContentLoaded &&
                                            favoritesContentLoaded) ||
                                            filterFavoritesVideos?.length !==
                                                0) &&
                                            item ===
                                                FavoritesCategories.VIDEOS &&
                                            `(${filterFavoritesVideos.length})`}
                                        {((audiosContentLoaded &&
                                            favoritesContentLoaded) ||
                                            filterFavoritesAudios?.length !==
                                                0) &&
                                            item ===
                                                FavoritesCategories.AUDIOS &&
                                            `(${filterFavoritesAudios.length})`}
                                        {documentUserFavorites?.length !== 0 &&
                                            item ===
                                                FavoritesCategories.DOCUMENTS &&
                                            `(${documentUserFavorites?.length})`}
                                    </AppTypography>
                                </ListItem>
                                <AppDivider />
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
                {(!favoritesContentLoaded ||
                    !audiosContentLoaded ||
                    !videosContentLoaded) && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={
                            !favoritesContentLoaded
                                ? messaging.loader.favorites
                                : !videosContentLoaded
                                ? messaging.loader.videos
                                : messaging.loader.audios
                        }
                    />
                )}
            </Box>
        </Layout>
    );
};
