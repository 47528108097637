import { ContentDetails, ContentResponse } from "JS/Models";
import { BaseService } from "../BaseService";
import { appConstants } from "JS/Helpers/Contants";
import { config } from "JS/Config";

export class VideoService extends BaseService {
    getVideos = () =>
        this.ngCall<ContentResponse>(
            "get",
            appConstants.ngEndpoints.videoListing(config.user.memberId),
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );

    getVideoDetails = (nid: string) =>
        this.ngCall<ContentDetails>(
            "get",
            appConstants.ngEndpoints.videoDetail(nid),
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );
}
