import { configureStore } from "@reduxjs/toolkit";
import { audioStateSlice } from "./Audio";
import { userHistoryStateSlice } from "./UserHistory";
import { videoStateSlice } from "./Video";
import { eventStateSlice } from "./Event";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { courseStateSlice } from "./Course";
import { giftStateSlice } from "./Gift";
import { webcastStateSlice } from "./Webcast";
import { homeStateSlice } from "./Home";
import { downloadStateSlice } from "./download";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import localforage from "localforage";
import thunk from "redux-thunk";
import { navStackSlice } from "./NavStack";
import { mediaStateSlice } from "./media";
import { playlistStateSlice } from "./Playlist";
import { playlistControlsSlice } from "./PlaylistControls";
import { favoritesStateSlice } from "./Favorites";
import { documentsStateSlice } from "./Documents";
import { recommendationsStateSlice } from "./Recommendations";
import { blackRecommendationsStateSlice } from "./BlackRecommendations";
import { downloadCourseStateSlice } from "./CourseDownload";
import { mediaEssentialsStateSlice } from "./MediaEssentials";
import { downloadCountStateSlice } from "./DownloadCount";
import { miniPlayerSlice } from "./MiniPlayer";
import { pushNotificationsStateSlice } from "./PushNotifications";
import { attributesStateSlice } from "./Attributes";
import { giftDashboardStateSlice } from "./GiftDashboard";
import { loggedInUserStateSlice } from "./User";
import { mixedContentStateSlice } from "./MixedContent";
import { consentStateSlice } from "./ConsentForm";
import { bundleOptInSlice } from "./BundleOptIn";
import { retiredContentStateSlice } from "./RetiredContent";
import { bundleSubscriptionSlice } from "./BundleSubscription";

const reducers = combineReducers({
    videos: videoStateSlice.reducer,
    audios: audioStateSlice.reducer,
    courses: courseStateSlice.reducer,
    gifts: giftStateSlice.reducer,
    events: eventStateSlice.reducer,
    webcasts: webcastStateSlice.reducer,
    home: homeStateSlice.reducer,
    media: mediaStateSlice.reducer,
    navStack: navStackSlice.reducer,
    download: downloadStateSlice.reducer,
    playlist: playlistStateSlice.reducer,
    playlistControls: playlistControlsSlice.reducer,
    favorites: favoritesStateSlice.reducer,
    userHistory: userHistoryStateSlice.reducer,
    documents: documentsStateSlice.reducer,
    recommendations: recommendationsStateSlice.reducer,
    blackRecommendations: blackRecommendationsStateSlice.reducer,
    downloadedCourse: downloadCourseStateSlice.reducer,
    mediaEssentials: mediaEssentialsStateSlice.reducer,
    downloadCount: downloadCountStateSlice.reducer,
    miniPlayer: miniPlayerSlice.reducer,
    pushNotifications: pushNotificationsStateSlice.reducer,
    attributes: attributesStateSlice.reducer,
    giftDashboard: giftDashboardStateSlice.reducer,
    user: loggedInUserStateSlice.reducer,
    mixedContent: mixedContentStateSlice.reducer,
    consentForm: consentStateSlice.reducer,
    bundleOptIn: bundleOptInSlice.reducer,
    bundleSubscription: bundleSubscriptionSlice.reducer,
    retiredContent: retiredContentStateSlice.reducer,
});

localforage.config({
    driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name: "WEB_MEDIA_APP",
    version: 1.0,
    size: 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName: "WEB_MEDIA_APP_STORE", // Should be alphanumeric, with underscores.
});
const persistConfig = {
    key: "REACT_APP_WEB_MEDIA",
    storage: localforage,
    blacklist: ["download", "blackRecommendations", "miniPlayer"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppStore> = useSelector;
