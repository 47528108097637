import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AudioDexie, VideoDexie } from "JS/Database/Dexie";
import {
    Content,
    ContentWithMedia,
    Gifts,
    OfflineBundle,
    RecommendationSource,
} from "JS/Models";

export interface MiniPlayerState {
    url: string;
    thumbnail: string;
    title: string;
    parentLink: string;
    isAudio: boolean;
    playbackRate?: number;
    content?: Content | ContentWithMedia | Gifts;
    offlineContent?: AudioDexie | VideoDexie;
    singleLoop?: boolean;
    isPlaying?: boolean | undefined;
    forceStopPlaying?: boolean;
    contentType: string;

    recommendation?: {
        recId: number;
        displayCount: number;
        source: RecommendationSource;
    };
    playlist?: {
        data: (ContentWithMedia | Gifts)[];
        playlistId: string;
        mediaIndex: number;
        bundleIndex?: number;
    };
    bundleAudio?: {
        data: ContentWithMedia | Gifts | OfflineBundle;
        mediaIndex?: number;
        type: "online" | "offline";
    };
}

const initialState: MiniPlayerState = {
    url: "",
    parentLink: "",
    thumbnail: "",
    title: "",
    isAudio: false,
    playbackRate: 1,
    forceStopPlaying: false,
    contentType: "",
};

export const miniPlayerSlice = createSlice({
    name: "miniPlayer",
    initialState,
    reducers: {
        setGlobalMiniPlayer: (
            state,
            data: PayloadAction<Partial<MiniPlayerState>>,
        ) => {
            state = {
                ...state,
                ...data.payload,
            };
            return state;
        },
        resetGlobalMiniPlayer: (
            state,
            data?: PayloadAction<MiniPlayerState["isPlaying"]>,
        ) => {
            return {
                ...initialState,
                playbackRate: state.playbackRate,
                isPlaying: data?.payload ? data.payload : state.isPlaying,
            };
        },
        resetMiniPlayerOnClose: (
            state,
            data?: PayloadAction<MiniPlayerState["isPlaying"]>,
        ) => {
            return {
                ...initialState,
                isPlaying: data?.payload ? data.payload : undefined,
            };
        },
    },
});
export const {
    resetGlobalMiniPlayer,
    setGlobalMiniPlayer,
    resetMiniPlayerOnClose,
} = miniPlayerSlice.actions;
