import { Box, InternalStandardProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AudioDexie, VideoDexie } from "JS/Database/Dexie";
import { CONTENT_TILE_SIZE } from "JS/Helpers/Contants";
import { Content, Course, DownloadedCourseDetail, Gifts } from "JS/Models";
import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import qs from "qs";
import { useGestureToScroll } from "JS/React/Hooks/Common/GestureControl";

export interface ListProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    data: (Content | AudioDexie | Gifts | Course | DownloadedCourseDetail)[];
    searchQuery?: string;
    handleRedirectToDetail?: (
        nid: string,
        type?: string,
        isAutoPlay?: boolean,
        scrollTo?: number,
    ) => void;
    isResumeableContent?: (filterAudio: AudioDexie) => boolean;
    handleRedirectToCourseDetail?: (
        c: Course | DownloadedCourseDetail,
        index: number,
    ) => void;
    controlType: string;
}
export const OfflineListingComponent = ({
    data,
    searchQuery,
    handleRedirectToDetail,
    isResumeableContent,
    handleRedirectToCourseDetail,
    controlType,
}: ListProps) => {
    const classes = useStyles();
    const { scrollTo } = useQueryParams();
    const listRef = createRef<FixedSizeList>();
    const scrollControlRef = useRef<boolean>(false);
    const [isMounted, setMounted] = useState(false);
    const searchScrollRef = useRef<boolean>(false);
    const { addScrollIndexInListingUrl } = useGestureToScroll();

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (
            isMounted &&
            +scrollTo >= 0 &&
            listRef.current &&
            !scrollControlRef.current
        ) {
            listRef.current.scrollToItem(+scrollTo, "center");
            scrollControlRef.current = true;
        }
    }, [scrollTo, data, listRef, isMounted]);
    useEffect(() => {
        if (listRef.current && searchQuery && searchScrollRef.current) {
            listRef?.current?.scrollToItem(0, "start");
        }
        searchScrollRef.current = true;
    }, [searchQuery]);
    const getContentAndDownloadStatus = (
        itemContent: Content | AudioDexie | Gifts,
    ) => {
        let content: {
            title: string;
            skuId: string;
            nid: string;
        };
        let isDownloaded = false;
        if (controlType === "audio") {
            const a = itemContent as AudioDexie;
            if (a.skuId) {
                content = {
                    skuId: a.skuId,
                    title: a.name,
                    nid: a.id,
                };
                isDownloaded = true;
            } else {
                const a = itemContent as Content;
                content = {
                    skuId: a.sku_id,
                    title: a.title,
                    nid: a.nid,
                };
            }
        } else {
            const v = itemContent as VideoDexie;
            if (v.skuId) {
                const a = itemContent as VideoDexie;
                content = {
                    title: a.name,
                    skuId: a.skuId,
                    nid: a.id,
                };
                isDownloaded = true;
            } else {
                const a = itemContent as Content;
                content = {
                    title: a.title,
                    skuId: a.sku_id,
                    nid: a.nid,
                };
            }
        }

        return { content, isDownloaded };
    };
    const isDownloadedCourse = (course: Course | DownloadedCourseDetail) =>
        !!(course as DownloadedCourseDetail)?.steps;
    const getCourseProps = (elem: DownloadedCourseDetail | Course) => {
        if (controlType === "course") {
            return {
                sender: (elem as any).sender,
            };
        }
        return {};
    };
    return (
        <Box className={classes.listHeight}>
            <AutoSizer>
                {({ height, width }) => (
                    <FixedSizeList
                        height={height}
                        itemCount={data.length || 0}
                        itemSize={CONTENT_TILE_SIZE}
                        width={width}
                        ref={listRef}
                    >
                        {({ index, style }) => {
                            const { content, isDownloaded } =
                                getContentAndDownloadStatus(
                                    data[index] as Content | AudioDexie | Gifts,
                                );
                            return (
                                <AppContentListItem
                                    key={index}
                                    style={style}
                                    nid={content.nid}
                                    type="media"
                                    title={content.title}
                                    skuId={content.skuId}
                                    description={data[index].description}
                                    releaseDate={
                                        controlType === "course"
                                            ? (
                                                  data[
                                                      index
                                                  ] as DownloadedCourseDetail
                                              )?.releaseDate?.toString() ||
                                              (
                                                  data[index] as Course
                                              )?.release_date?.toString()
                                            : (data[index] as Content)
                                                  ?.release_date
                                    }
                                    disabled={
                                        controlType === "course"
                                            ? !isDownloadedCourse(
                                                  data[index] as
                                                      | Course
                                                      | DownloadedCourseDetail,
                                              )
                                            : !isDownloaded
                                    }
                                    isResume={
                                        controlType !== "course" &&
                                        isDownloaded &&
                                        isResumeableContent(
                                            data[index] as
                                                | AudioDexie
                                                | VideoDexie,
                                        )
                                    }
                                    sender={
                                        controlType === "course"
                                            ? (data[index] as any).sender
                                            : false
                                    }
                                    imageUrl={""}
                                    showPlayIcon
                                    isAllowClick={
                                        controlType === "course"
                                            ? true
                                            : isDownloaded
                                    }
                                    onListItemClick={
                                        controlType === "course"
                                            ? () => {
                                                  addScrollIndexInListingUrl(
                                                      index,
                                                  );
                                                  handleRedirectToCourseDetail(
                                                      data[index] as
                                                          | Course
                                                          | DownloadedCourseDetail,
                                                      index,
                                                  );
                                              }
                                            : () => {
                                                  addScrollIndexInListingUrl(
                                                      index,
                                                  );
                                                  handleRedirectToDetail(
                                                      (
                                                          data[index] as
                                                              | AudioDexie
                                                              | VideoDexie
                                                      ).id,
                                                      controlType,
                                                      false,
                                                      index,
                                                  );
                                              }
                                    }
                                    onItemPlayClick={
                                        controlType !== "course"
                                            ? () => {
                                                  addScrollIndexInListingUrl(
                                                      index,
                                                  );
                                                  handleRedirectToDetail(
                                                      (
                                                          data[index] as
                                                              | AudioDexie
                                                              | VideoDexie
                                                      ).id,
                                                      "audio",
                                                      false,
                                                      index,
                                                  );
                                              }
                                            : undefined
                                    }
                                />
                            );
                        }}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </Box>
    );
};
const useQueryParams = () => {
    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { scrollTo = "0" } = parsedQueryString;

        return {
            scrollTo: scrollTo.toString(),
        };
    }, [location?.search]);

    return queryParams;
};
const useStyles = makeStyles((theme: Theme) => ({
    listHeight: {
        height: `calc(100vh - ${theme.footer.height})`,
    },
}));
