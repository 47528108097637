import { ContentDetails, ContentResponse } from "JS/Models";
import { BaseService } from "../BaseService";
import { appConstants } from "JS/Helpers/Contants";
import { config } from "JS/Config";

export class AudioService extends BaseService {
    getAudios = () =>
        this.ngCall<ContentResponse>(
            "get",
            appConstants.ngEndpoints.audioListing(config.user.memberId),
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );

    getAudioDetails = (nid: string) =>
        this.ngCall<ContentDetails>(
            "get",
            appConstants.ngEndpoints.audioDetail(nid),
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );
}
