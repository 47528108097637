import { appConstants } from "JS/Helpers/Contants";
import { BaseService } from "../BaseService";
import { config } from "JS/Config";

export type FavoriteContentType = "Audio" | "Video";
export class FavoritesService extends BaseService {
    getFavorites = () =>
        this.ngCall<string[]>(
            "get",
            appConstants.ngEndpoints.favoritesListing(config.user.memberId),
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );

    markFavorite = (nid: string) =>
        this.ngCall<{}>(
            "post",
            appConstants.ngEndpoints.markFavorite,
            {
                nid: +nid,
                memberId: config.user.memberId,
            },
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );

    unMarkFavorite = (nid: string) =>
        this.ngCall<{}>(
            "post",
            appConstants.ngEndpoints.unmarkFavorite,
            {
                nid: +nid,
                memberId: config.user.memberId,
            },
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );
}
