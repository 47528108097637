import {
    AudioDexie,
    CourseDexie,
    DocumentDexie,
    VideoDexie,
} from "JS/Database/Dexie";
import { DownloadedCourseDetail } from "./Courses";
import { FirebaseEventAction } from "./Firebase";
import { Gifts } from "./Media";
import { Content, ContentWithMedia } from "./Audio";
import { MiniPlayerState } from "JS/Redux/MiniPlayer";

export interface ContentMedia {
    media_title: string;
    media_url_prefix: string;
    media_bucket_name: string;
    media_url_postfix: string;
    media_file_name: string;
    position: number;
}

export interface OfflineBundle {
    id?: string;
    name?: string;
    media?: {
        media_title: string;
        position: number;
        blob: Blob;
    }[];
    skuId?: string;
    description?: string;
    release_date?: string;
}

export type QueueItemContentType =
    | "audioBundle"
    | "giftAudioBundle"
    | "video"
    | "giftVideo"
    | "audio"
    | "giftAudio"
    | "document"
    | "courseMP3"
    | "coursePdf"
    | "courseVideo";

export interface BaseQueueItems {
    name: string;
    type: QueueItemContentType;
    nid: string;
    description: string;
    media_url_prefix?: string;
    media_url_postfix?: string;
    media_file_name?: string;
    skuId?: string;
    release_date?: string;
}

export interface QueueItem extends BaseQueueItems {
    status: string;
    tries: number;
    media_title?: string;
    position?: number;
    no_of_files?: number;
    courseNid?: DownloadedCourseDetail["nid"];
    courseSkuId?: DownloadedCourseDetail["sku_id"];
    courseType?: DownloadedCourseDetail["content_item_type_name"];
}

export interface AddToDownloadOptions extends BaseQueueItems {
    media?: ContentMedia[];
}

export interface LogFirebaseEventDownloadOptions
    extends Omit<FirebaseEventAction, "category"> {
    category: QueueItemContentType;
}

export interface DocumentQueueItem {
    name: string;
    nid: string;
    type: string;
    status: string;
    description: string;
    tries: number;
}

export type DownloadQueueItemsType = QueueItem | DocumentQueueItem;

export type DexieDbTypes =
    | VideoDexie
    | AudioDexie
    | DocumentDexie
    | CourseDexie;

export interface MediaTransactionDialogs {
    transactionComplete: boolean;
    transactionConfirmation: boolean;
    selectMembers: boolean;
}

export enum GiftsTypes {
    MEMBER = "Member",
    PROSPECT = "Prospect",
    DEFAULT = "",
}

export type VideosAndGifts = Content | Gifts;
export type AudiosAndGifts = Content | ContentWithMedia;

export interface MiniPlayerOptions {
    mediaUrl?: string;
    thumbnail: string;
    title: string;
    parentLink?: string;
    playlist?: MiniPlayerState["playlist"];
    bundleAudio?: MiniPlayerState["bundleAudio"];
    content?: MiniPlayerState["content"];
    offlineContent?: MiniPlayerState["offlineContent"];
    recommendation?: MiniPlayerState["recommendation"];
    singleLoop?: MiniPlayerState["singleLoop"];
    isPlaying: boolean;
    contentType?: string;
}
