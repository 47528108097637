import { config } from "JS/Config";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";
import { BundleSubscription } from "JS/Models/BundleSubscription";

export class BundleSubscriptionService extends BaseService {
    async getBundleSubscriptionStatus() {
        return (
            await this.doXHR<AppResponse<BundleSubscription>>({
                url: this.routes.server.api.bundleSubscription.getBundleSubscriptionStatus(
                    config?.user?.memberId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }
}
