import {
    dataURLtoFile,
    getFileExtenstionFromName,
    getPublicUrl,
    getSignedPublicUrl,
} from "JS/Helpers";
import { useSetProfileImage } from "JS/React/Hooks/Events";
import { config } from "JS/Config";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";
import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import { captureSentryError } from "JS/Helpers/SentryHelper";
import { Content, Course, Gifts, ReceivedCourse } from "JS/Models";

export const useImageUpload = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { refetch: uploadProfileImage, loading: uploadingImage } =
        useSetProfileImage();
    const [fetchingImage, setFetchingImage] = useState<boolean>(false);
    const history = useHistory();

    const uploadImageHandler = useCallback((event, userId: string) => {
        let _validFileExtensions = [
            "png",
            "gif",
            "pjp",
            "jpg",
            "pjpep",
            "jpeg",
            "jfif",
        ];
        if (
            _validFileExtensions.includes(
                getFileExtenstionFromName(event.target.files[0].name),
            )
        ) {
            return uploadProfileImage(
                event.target.files[0],
                userId,
                userId === config?.user?.memberId ? 1 : 0,
            );
        } else {
            enqueueSnackbar(messaging?.events?.selectValidFormat, {
                variant: "warning",
            });
            return null;
        }
    }, []);

    const webCamImageHandler = useCallback(
        (
            setDialogs: (
                value: React.SetStateAction<{
                    uploadImageDialog: boolean;
                    webCamImageDialog: boolean;
                }>,
            ) => void,
            dialogs: { uploadImageDialog: boolean; webCamImageDialog: boolean },
        ) => {
            let constraints = {
                audio: false,
                video: true,
            };
            return navigator.mediaDevices.getUserMedia(constraints).then(
                () => {
                    setDialogs({
                        ...dialogs,
                        webCamImageDialog: true,
                    });
                },
                (error) => {
                    if (error.name === "NotAllowedError") {
                        enqueueSnackbar(messaging?.events?.camaraError, {
                            variant: "warning",
                        });
                    }
                },
            );
        },
        [],
    );

    const captureUploadHandler = useCallback(
        async (
            setDialogs: (
                value: React.SetStateAction<{
                    uploadImageDialog: boolean;
                    webCamImageDialog: boolean;
                }>,
            ) => void,
            dialogs: { uploadImageDialog: boolean; webCamImageDialog: boolean },
            userId: string,
            capturedImage: any,
        ) => {
            let file = await dataURLtoFile(capturedImage, userId);
            if (file) {
                setDialogs({
                    ...dialogs,
                    webCamImageDialog: false,
                });
                return uploadProfileImage(
                    file,
                    userId,
                    userId === config.user.memberId ? 1 : 0,
                );
            }
        },
        [],
    );

    const closeUploadDialogHandler = useCallback(
        (
            setDialogs: (
                value: React.SetStateAction<{
                    uploadImageDialog: boolean;
                    webCamImageDialog: boolean;
                }>,
            ) => void,
            isImageExists: boolean,
            urlPath: string,
        ) => {
            setDialogs({
                uploadImageDialog: false,
                webCamImageDialog: false,
            });
            if (!isImageExists) {
                enqueueSnackbar(
                    urlPath?.includes("events")
                        ? messaging?.events?.uploadPhotoToViewTicket
                        : messaging?.fycStatus?.uploadPhoto,
                    {
                        variant: "warning",
                    },
                );
                history.push(urlPath);
            }
        },
        [],
    );

    const fetchImageUrl = useCallback(
        (
            userId: string,
            setBackgroundImageUrl: React.Dispatch<React.SetStateAction<string>>,
        ) => {
            setFetchingImage(true);
            getSignedPublicUrl(
                `${config.s3.baseURL}/${config.s3.events?.bucketName}/${config?.s3?.events?.imagePath}${userId}.jpg`,
            )
                .then((res) => {
                    setBackgroundImageUrl(res);
                })
                .catch((err) =>
                    captureSentryError(err, {
                        location: `getUserImageFromS3InFycStatus`,
                    }),
                )
                .finally(() => setFetchingImage(false));
        },
        [],
    );

    return {
        uploadImageHandler,
        webCamImageHandler,
        captureUploadHandler,
        closeUploadDialogHandler,
        fetchImageUrl,
        uploadingImage,
        fetchingImage,
    };
};

export const createImageUrl = async (
    content: Content | Content | Gifts | Course | ReceivedCourse,
) => {
    const { image_url_prefix, image_url_postfix, image_file_name } =
        "image" in content ? content.image : content;
    const url = await getPublicUrl(
        image_url_prefix,
        image_url_postfix,
        image_file_name,
    );

    return {
        id: content.nid,
        url: url,
    };
};

interface Thumbnail {
    id: string;
    url: string;
}

interface Thumbnails {
    [key: string]: string;
}

export const useFetchThumbnails = (): {
    thumbnails: Thumbnails;
    refetch: (
        finalData: (Content | Content | Gifts | Course | ReceivedCourse)[],
    ) => Promise<void>;
} => {
    const [thumbnails, setThumbnails] = useState<Thumbnails>({});

    const refetch = async (
        finalData: (Content | Content | Gifts | Course | ReceivedCourse)[],
    ) => {
        let promises: Promise<Thumbnail>[] = [];
        finalData.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);
        let toRet: Thumbnails = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    return {
        thumbnails,
        refetch,
    };
};
