import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import clsx from "clsx";
import { uniqBy } from "lodash-es";
import { InternalStandardProps } from "@mui/material";

import {
    useGlobalGifts,
    useReceivedMemberVideoGifts,
    useReceivedProspectVideoGifts,
} from "JS/React/Hooks/Gifts";
import { useGlobalVideos, useVideos } from "JS/React/Hooks/Video";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRouting } from "JS/React/Hooks/Routes";
import { useScrollWithoutDelay } from "JS/React/Hooks/Media";
import { Content, Gifts } from "JS/Models";
import { videoCategoryTitleToString } from "JS/Helpers/Helpers";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { getDexieConnectionRefresh, VideoDexie } from "JS/Database/Dexie";
import { config } from "JS/Config";
import {
    VideoCategoryTitle,
    useFilterVideoHandlers,
    useVideoAttributesHandlers,
} from "./index";
import { Layout } from "../Layout";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { ListingComponent } from "../Audios/ListingComponent";
import { useVideosHandlers } from "./VideoHooks";
import { useVideoResumeHelper } from "./VideoHelpers";
import { useDownloadedContentStatus } from "./DownloadedContentStatus";
import { useRetiredContent } from "JS/React/Hooks/RetiredContent";
import { useAppSelector } from "JS/Redux/Store";
import { getUniqueValues } from "JS/Helpers";
export interface VideosContentListingProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const VideosContentListing = (props: VideosContentListingProps) => {
    const { linkProvider } = useRouting();
    const { className, ...rest } = props;
    const {
        setGlobalVideosStack,
        resetGlobalVideosStack,
        videosStack,
        homeStack,
    } = useGlobalNavStack();
    const [filteredVideos, setFilteredVideos] = useState<Content[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(
        videosStack?.searchQuery,
    );
    const [headerTitle, setHeaderTitle] = useState("");
    const { favNids } = useGlobalFavorites();

    useGetFavorites(!!favNids?.length);

    const history = useHistory();

    const { videos, categories, isVideoUpdateRequired } = useGlobalVideos();

    const { loading } = useVideos(
        !!videos && videos.length > 0,
        isVideoUpdateRequired,
    );

    const { receivedMemberVideoGifts, receivedProspectVideoGifts } =
        useGlobalGifts();

    const { loading: receivedMemberVideoGiftsLoading } =
        useReceivedMemberVideoGifts(
            !!receivedMemberVideoGifts && receivedMemberVideoGifts.length > 0,
        );

    const { loading: receivedProspectVideoGiftsLoading } =
        useReceivedProspectVideoGifts(!!receivedProspectVideoGifts?.length);

    const {
        mediaEssentials,
        videoUniqueSpeakers,
        videoSelectedSpeakers,
        attributesLoading,
        onToggleSpeakerSelect,
        videoContentSpeakers,
        handleClearFilters,
        handleResetVideoAttributes,
    } = useVideoAttributesHandlers();

    const params: any = useParams();
    const { categoryId } = params;
    const { handleListItemClick } = useVideosHandlers(searchQuery, categoryId);
    const [downloadVideos, setVideos] = useState<VideoDexie[]>([]);
    const { retiredContent, isRetVideoUpdateRequired } = useAppSelector(
        (state) => state.retiredContent,
    );
    const { loading: retContentLoading } = useRetiredContent(
        "Video,GiftVideo,GiftProsVideo",
        !!retiredContent?.Video?.length ||
            !!retiredContent?.GiftVideo?.length ||
            !!retiredContent?.GiftProsVideo?.length,
        isRetVideoUpdateRequired,
    );

    useEffect(() => {
        (async () => {
            if (categoryId === "saved-videos") {
                const toRetVideos: VideoDexie[] = [];
                await getDexieConnectionRefresh().videos.each((video) =>
                    toRetVideos.push(video),
                );

                setVideos(
                    toRetVideos.filter((d) =>
                        d.id.startsWith(config.user.memberId),
                    ),
                );
            }
        })();
    }, []);

    useEffect(() => {
        if (categoryId === VideoCategoryTitle.ALL_VIDEOS) {
            let data = [
                ...videos,
                ...receivedMemberVideoGifts,
                ...receivedProspectVideoGifts,
            ];
            data = data.filter(
                (video) =>
                    !retiredContent?.Video?.includes(video.nid) &&
                    !retiredContent?.GiftVideo?.includes(video.nid) &&
                    !retiredContent?.GiftProsVideo?.includes(video.nid),
            );
            setFilteredVideos(getUniqueValues(data, "nid"));
            setHeaderTitle(
                videoCategoryTitleToString(VideoCategoryTitle.ALL_VIDEOS),
            );
        } else if (categoryId === VideoCategoryTitle.MY_VIDEOS) {
            const videosList = videos?.filter(
                (video) => video.publish_free === "1" || video.is_purchased,
            );
            const data = [
                ...videosList,
                ...receivedMemberVideoGifts,
                ...receivedProspectVideoGifts,
            ];
            setFilteredVideos(uniqBy(data, (x) => x.nid));

            setHeaderTitle(
                videoCategoryTitleToString(VideoCategoryTitle.MY_VIDEOS),
            );
        } else if (categoryId === VideoCategoryTitle.RECENTLY_PLAYED) {
            const videosList = videos?.filter((video) => video.played !== "0");
            const data = [
                ...videosList,
                ...receivedMemberVideoGifts.filter(
                    (video) => video.played !== "0",
                ),
                ...receivedProspectVideoGifts.filter(
                    (video) => video.played !== "0",
                ),
            ];
            setFilteredVideos(uniqBy(data, (x) => x.nid));

            setHeaderTitle(
                videoCategoryTitleToString(VideoCategoryTitle.RECENTLY_PLAYED),
            );
        } else if (categoryId === VideoCategoryTitle.SAVED) {
            const videoNids = [];
            if (downloadVideos) {
                downloadVideos.forEach((a) =>
                    videoNids.push(a.id.split("-")[1]),
                );
            }

            const data = [
                ...videos?.filter((video) => videoNids?.includes(video.nid)),
                ...receivedMemberVideoGifts.filter((video) =>
                    videoNids?.includes(video.nid),
                ),
                ...receivedProspectVideoGifts.filter((video) =>
                    videoNids?.includes(video.nid),
                ),
            ];
            setFilteredVideos(uniqBy(data, (x) => x.nid));

            setHeaderTitle(
                videoCategoryTitleToString(VideoCategoryTitle.SAVED),
            );
        } else {
            const selectedVideos = videos?.filter((video) =>
                video.category_ids.includes(categoryId),
            );
            setFilteredVideos(selectedVideos);
            setHeaderTitle(
                categories?.find((c) => c?.category_id === categoryId)
                    ?.category_title || "Videos",
            );
        }
    }, [
        downloadVideos,
        categoryId,
        videos,
        receivedMemberVideoGifts,
        receivedProspectVideoGifts,
        retiredContent,
    ]);

    const { searchResults } = useFilterVideoHandlers(
        filteredVideos,
        searchQuery,
        videoSelectedSpeakers,
        videoContentSpeakers,
    );
    const useVideosDownloadHelperData = (data: (Content | Gifts)[]) => {
        const { getDownloadedStatusById } = useDownloadedContentStatus(
            data,
            "video",
        );
        return getDownloadedStatusById;
    };
    const { isResumable } = useVideoResumeHelper();
    useEffect(() => {
        setGlobalVideosStack({
            searchQuery: searchQuery,
        });
    }, [searchResults, videoSelectedSpeakers]);

    const isContentLoaded =
        !loading &&
        !receivedMemberVideoGiftsLoading &&
        !receivedProspectVideoGiftsLoading &&
        !attributesLoading &&
        !retContentLoading;

    useScrollWithoutDelay();

    return (
        <Layout>
            <div className={clsx(className)} {...rest}>
                <AppHeader
                    title={headerTitle || "Videos"}
                    canGoBack
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Videos by title/description"}
                    onBackClick={() => {
                        setFilteredVideos([]);
                        setSearchQuery("");
                        resetGlobalVideosStack();
                        handleResetVideoAttributes();
                        if (!homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.videos().index());
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                    filterable={mediaEssentials?.allow_speaker_feature}
                    onToggleSpeakerSelect={onToggleSpeakerSelect}
                    uniqueSpeakers={videoUniqueSpeakers}
                    selectedSpeakers={videoSelectedSpeakers}
                    handleClearFilters={handleClearFilters}
                />
                {isContentLoaded ? (
                    <ListingComponent
                        listContent={searchResults}
                        searchQuery={searchQuery}
                        categoryId={categoryId}
                        showSortHeader={true}
                        setGlobalStack={setGlobalVideosStack}
                        globalStack={videosStack}
                        isResumeable={isResumable}
                        favoritesContent={favNids}
                        handleListItemClick={handleListItemClick}
                        getDownloadHelperData={useVideosDownloadHelperData}
                        controlType="video"
                    />
                ) : (
                    <AppBackdropProgress
                        open={true}
                        backdropText={
                            !attributesLoading
                                ? messaging.loader.videos
                                : messaging.loader.fectchingSpeakers
                        }
                    />
                )}
            </div>
        </Layout>
    );
};
