import { Gifts, Content } from "JS/Models";
import { useGlobalVideos } from "JS/React/Hooks/Video";

export const useVideoResumeHelper = () => {
    const { resumeUserVideos } = useGlobalVideos();

    const isResumable = (videoItem: Content | Gifts) => {
        const resumeVideo = resumeUserVideos?.find(
            (x) => x.nid === videoItem.nid,
        );
        return !!resumeVideo;
    };

    return { isResumable };
};
