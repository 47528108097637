import { AudioService } from "JS/Services/Audio";
import { useState, useCallback, useEffect } from "react";
import {
    Content,
    Gifts,
    ResumeContent,
    ContentDetails as ContentDetail,
    PlaylistAudio,
} from "JS/Models";
import { createOfflineIDBID, sortArrayByKey } from "JS/Helpers";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    setAudioDetails,
    setGlobalAudios,
    setGlobalPlayedAudios,
    setUserResumeAudios,
    setUserResumeBundles,
} from "JS/Redux/Audio";
import { setGlobalMedia } from "JS/Redux/media";
import moment from "moment";
import { config } from "JS/Config";
import { useAudioIndexFromDb, useBulkUpdateAudiosDB } from "./Database/Audio";
import { keyBy } from "lodash";
import { AudioDexie } from "JS/Database/Dexie";
import {
    AudioVideoResume,
    PlayedAudiosVideos,
} from "JS/Models/Firebase/Resume";
import { useGlobalMediaEssentials } from "./MediaEssentials";
import { messaging } from "JS/Helpers/UserMessaging";

export const service = new AudioService();

export const useAudios = (skip: boolean, isUpdateRequired: boolean) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>(null);
    const [audios, setAudios] = useState<Content[]>([]);

    const { playedAudios, resumeAudios, resumeBundles } = useGlobalAudios();
    const playedAudiosList =
        playedAudios && playedAudios[config.user.memberId]
            ? playedAudios[config.user.memberId]
            : [];
    const dispatch = useAppDispatch();
    const { syncOfflineAudios } = useSyncOfflineAudios();
    const { mediaEssentials } = useGlobalMediaEssentials();
    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(async () => {
        setLoading(true);
        return service
            .getAudios()
            .then((response) => {
                if (!response?.status) throw Error(messaging.common.error);
                const data = response.data;
                syncOfflineAudios(data.content);
                const allAudios = data.content.map((item) => {
                    if (
                        playedAudiosList.filter((x) => x.nid === item.nid)
                            .length > 0
                    ) {
                        return {
                            ...item,
                            played: playedAudiosList.filter(
                                (x_1) => x_1.nid === item.nid,
                            )[0].played,
                            isReceived: false,
                        };
                    } else {
                        return {
                            ...item,
                            isReceived: false,
                            played: "0",
                        };
                    }
                });
                const audioList = sortArrayByKey(allAudios, "title", "ASC");
                setAudios(audioList);
                const categories = sortArrayByKey(
                    data.categories,
                    "category_title",
                    "ASC",
                );
                dispatch(
                    setGlobalAudios({
                        audios: audioList,
                        categories: categories,
                        playedAudios: playedAudios ? { ...playedAudios } : {},
                        resumeAudios: resumeAudios ? { ...resumeAudios } : {},
                        resumeBundles: resumeBundles
                            ? { ...resumeBundles }
                            : {},
                    }),
                );
                dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
                return {
                    allAudios,
                    categories,
                };
            })
            .catch((err) => {
                setErrorMsg(err.message);
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    allAudios: [],
                    categories: [],
                };
            })
            .finally(() => {
                setLoaded(true);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (
            !skip ||
            (mediaEssentials?.allow_rule_engine_requests && isUpdateRequired)
        )
            refetch();
    }, [skip, isUpdateRequired]);

    return {
        refetch,
        loading,
        loaded,
        errorMsg,
        audios,
    };
};

export const useAudioDetails = (nid: string, shouldFetch: boolean = true) => {
    const [loading, setLoading] = useState(false);
    const { setAudioDetail } = useGlobalAudios();
    const { enqueueSnackbar } = useSnackbar();
    const { audioDetails } = useGlobalAudioDetails(nid);

    useEffect(() => {
        if (!shouldFetch) return;
        setLoading(true);
        service
            .getAudioDetails(nid)
            .then(async (response) => {
                if (!response?.data) throw Error();
                setAudioDetail(nid, response.data);
            })
            .catch(() => {
                if (!audioDetails)
                    enqueueSnackbar(messaging.common.error, {
                        variant: "error",
                    });
            })
            .finally(() => setLoading(false));
    }, [nid, shouldFetch]);

    return {
        loading,
    };
};

export const useGlobalAudios = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.audios);
    const played = useAppSelector((state) => state.audios?.playedAudios);
    const resumeAudios =
        useAppSelector((state) => state.audios?.resumeAudios) || {};
    const resumeBundles = useAppSelector(
        (state) => state.audios?.resumeBundles,
    );

    const resumeUserAudios =
        resumeAudios && resumeAudios[`${config?.user?.memberId}`]
            ? resumeAudios[`${config?.user?.memberId}`]
            : [];

    const playedUserAudios =
        played && played[`${config?.user?.memberId}`]
            ? played[`${config?.user?.memberId}`]
            : [];
    const resumeUserBundles =
        resumeBundles && resumeBundles[`${config?.user?.memberId}`]
            ? resumeBundles[`${config?.user?.memberId}`]
            : [];
    const currentPlayedMap = played ? played : {};
    const currentResumeAudiosMap = resumeAudios ? resumeAudios : {};
    const currentResumeBundlesMap = resumeBundles ? resumeBundles : {};

    return {
        audios: allData?.audios || [],
        isAudioUpdateRequired: allData?.isUpdateRequired,
        playedAudios: currentPlayedMap,
        playedUserAudios,
        resumeAudios: resumeAudios || {},
        resumeBundles: resumeBundles || {},
        resumeUserAudios: resumeUserAudios,
        resumeUserBundles: resumeUserBundles,
        categories: allData?.categories || [],
        details: allData?.audioDetails,

        setGlobalAudios: (audios: Content[]) => {
            dispatch(
                setGlobalAudios({
                    ...allData,
                    audios,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },

        updateRecentlyPlayed: (audio: Content | Gifts) => {
            const currentPlayed: (Content | Gifts | PlayedAudiosVideos)[] =
                currentPlayedMap[config.user.memberId] || [];
            const newMap = { ...currentPlayedMap };
            const newPlayed = [...currentPlayed].filter(
                (c) => c.nid !== audio.nid,
            );
            newPlayed.push(audio);
            newMap[config.user.memberId] = newPlayed;
            dispatch(setGlobalPlayedAudios(newMap));
        },

        updateResumeAudios: (resumeAudio: ResumeContent) => {
            const currentUserResume: ResumeContent[] = currentResumeAudiosMap[
                config.user.memberId
            ]
                ? currentResumeAudiosMap[config.user.memberId]
                : [];
            const newMap = { ...currentResumeAudiosMap };
            const newResume = [...currentUserResume].filter(
                (c) => c.nid !== resumeAudio.nid,
            );
            newResume.push(resumeAudio);
            newMap[config.user.memberId] = newResume;
            dispatch(setUserResumeAudios(newMap));
        },

        updateResumeBundles: (resumeAudio: ResumeContent) => {
            const userBundleList: ResumeContent[] =
                currentResumeBundlesMap[config.user.memberId] || [];

            const newMap = { ...currentResumeBundlesMap };
            if (
                userBundleList.filter(
                    (c) =>
                        c.nid === resumeAudio.nid &&
                        c.title === resumeAudio.title,
                ).length > 0
            ) {
                let newResume = [...userBundleList];
                const index = newResume.findIndex(
                    (x) =>
                        x.nid === resumeAudio.nid &&
                        x.title === resumeAudio.title,
                );
                newResume[index] = resumeAudio;
                newMap[config.user.memberId] = newResume;
            } else {
                let newResume = [...userBundleList];
                newResume.push(resumeAudio);
                newMap[config.user.memberId] = newResume;
            }

            dispatch(setUserResumeBundles(newMap));
        },
        syncResumeAudios: (resumeAudiosData: AudioVideoResume[]) => {
            dispatch(
                setUserResumeAudios({
                    [config.user.memberId]: resumeAudiosData,
                }),
            );
        },
        syncResumeBundles: (resumeBundlesData: AudioVideoResume[]) => {
            dispatch(
                setUserResumeBundles({
                    [config.user.memberId]: resumeBundlesData,
                }),
            );
        },
        syncPlayedAudios: (playedAudios: PlayedAudiosVideos[]) => {
            dispatch(
                setGlobalPlayedAudios({
                    [config.user.memberId]: playedAudios,
                }),
            );
        },
        setPlaylistAudioDetail: (audios: PlaylistAudio[]) => {
            const audioDetailsMap = !!allData.audioDetails
                ? { ...allData.audioDetails }
                : {};
            audios.forEach(
                (a) =>
                    (audioDetailsMap[a.nid] = {
                        media: a.media,
                        description: a.description,
                    }),
            );
            dispatch(setAudioDetails(audioDetailsMap));
        },
        setAudioDetail: (nid: string, detail: ContentDetail) => {
            const audioDetailsMap = !!allData.audioDetails
                ? { ...allData.audioDetails }
                : {};
            audioDetailsMap[nid] = detail;
            dispatch(setAudioDetails(audioDetailsMap));
        },
        getAudioDetails: (nid: string) => {
            const details = allData?.audioDetails;
            return !!details ? details[nid] : undefined;
        },
    };
};

export const useGlobalAudioDetails = (nid: string) => {
    const { details } = useGlobalAudios();

    return {
        audioDetails: !!details ? details[nid] : undefined,
    };
};

export const useSyncOfflineAudios = () => {
    const { refetch: refetchOfflineAudios } = useAudioIndexFromDb(true);
    const { bulkUpdateAudios } = useBulkUpdateAudiosDB();

    const syncOfflineAudios = useCallback(
        async (audios: (Gifts | Content)[]) => {
            const offlineAudios = await refetchOfflineAudios();

            if (offlineAudios && offlineAudios.length) {
                const allOfflineIds = offlineAudios.map((d) => d.id);

                //get the details of the audios which are downloaded
                const filteredAudios = audios.filter((x) => {
                    const offlineId = createOfflineIDBID(
                        config.user.memberId,
                        x.nid,
                    );

                    if (+x.no_of_files > 1) {
                        //for bundles id will be like userid-nid-position
                        //include this bundle even if one audio in that is downloaded
                        return allOfflineIds.some((id) =>
                            id.includes(offlineId),
                        );
                    }

                    return allOfflineIds.includes(offlineId);
                });

                const newOfflineAudios = createUpdatedOfflineAudios(
                    offlineAudios,
                    filteredAudios,
                );

                await bulkUpdateAudios(newOfflineAudios);
            }
        },
        [bulkUpdateAudios, refetchOfflineAudios],
    );

    const createUpdatedOfflineAudios = (
        offlineAudios: AudioDexie[],
        filteredAudios: (Gifts | Content)[],
    ) => {
        const offlineAudMap = keyBy(offlineAudios, (x) => x.id);
        const newOfflineAudios: AudioDexie[] = [];

        filteredAudios.forEach((v) => {
            const offlineId = createOfflineIDBID(config.user.memberId, v.nid);
            if (+v.no_of_files === 1) {
                const currAud = offlineAudMap[offlineId];
                newOfflineAudios.push({
                    ...currAud,
                    name: v.title,
                });
            }
        });

        return newOfflineAudios;
    };

    return {
        syncOfflineAudios,
    };
};
