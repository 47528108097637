import { config } from "JS/Config";
import {
    getInitialValidityFromRules,
    ValidationRules,
    ValidationTypes,
} from "JS/Helpers";
import {
    ILogin,
    LoginResponse,
    SeamlessUpdateResponse,
    TokenResponse,
} from "JS/Models";
import { AppAlternativeResponse, AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";
import { appConstants } from "JS/Helpers/Contants";

export class UsersService extends BaseService {
    async login(loginData: ILogin) {
        const formData = new FormData();

        formData.append("username", `${loginData.userName}`);
        formData.append("password", loginData.password);
        formData.append("tenantId", "ltd");
        formData.append("clientId", "2");
        formData.append("scope", "media_scope");
        formData.append("platform", "web");

        return (
            await this.doXHR<AppResponse<LoginResponse>>({
                url: this.routes.server.api.users.login(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async seamlessUpdate(lastCachedTime: number) {
        return (
            await this.doXHR<AppAlternativeResponse<SeamlessUpdateResponse>>({
                url: this.routes.server.api.users.seamlessUpdate(
                    config?.user.memberId,
                    config?.appVersion,
                    lastCachedTime,
                    config?.betaAppVersion,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        )?.data;
    }

    createToken = () =>
        this.ngCall<TokenResponse>(
            "get",
            appConstants.ngEndpoints.accessToken,
            {
                params: {
                    tenantId: "ltd",
                    clientId: 2,
                    memberId: "6036220",
                    scope: "media_scope",
                    access_token: "",
                },
            },
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );

    refreshToken = () =>
        this.ngCall<TokenResponse>(
            "post",
            appConstants.ngEndpoints.refreshToken,
            {
                params: {
                    refreshToken: "",
                    tenantId: "ltd",
                    clientId: 2,
                    memberId: "6036220",
                    scope: "media",
                },
            },
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );
}

export const loginValidityRules: ValidationRules<keyof ILogin> = {
    userName: [
        { rule: ValidationTypes.REQUIRED, message: "User Name is required" },
        { rule: ValidationTypes.NUMBER, message: "User Name should be number" },
    ],
    password: [
        { rule: ValidationTypes.REQUIRED, message: "Password is required" },
    ],
};

export const dysValidityRules: ValidationRules<keyof ILogin> = {
    userName: [
        { rule: ValidationTypes.REQUIRED, message: "User Name is required" },
    ],
    password: [
        { rule: ValidationTypes.REQUIRED, message: "Password is required" },
    ],
};

export function validateLogin(login: ILogin) {
    const state = getInitialValidityFromRules(loginValidityRules, login);

    return state;
}

export function validateDYSLogin(login: ILogin) {
    const state = getInitialValidityFromRules(dysValidityRules, login);

    return state;
}
