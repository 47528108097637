import { useEffect, useMemo } from "react";
import clsx from "clsx";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Layout } from "../Layout";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { useRouting } from "JS/React/Hooks/Routes";
import { AppFallBackComponent } from "JS/React/Components/AppFallBackComponent";
import { config } from "JS/Config";
import { useAudios } from "JS/React/Hooks/Audio";
import {
    useReceivedMemberAudioGifts,
    useReceivedMemberVideoGifts,
} from "JS/React/Hooks/Gifts";
import { useCourses, useReceivedCourses } from "JS/React/Hooks/Course/Course";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { GiftCategories } from "../Gifts";
import { AllTabs } from "JS/Models";
import { Content, Gifts } from "JS/Models";
import { AudioCategoryTitle } from "../Audios";
import { useVideos } from "JS/React/Hooks/Video";
import { VideoCategoryTitle } from "../Videos";

export const AttachedSku = (props: AttachedSkuProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const { attachmentType, mediaLinkId, memberId } = useQueryParams();

    const isSameUser = memberId === config.user.memberId;

    const { linkProvider } = useRouting();
    const { filteredMedia, receivedCourses, courses, loading } = useContent(
        isSameUser,
        attachmentType,
    );
    const { redirectToDesiredPage } = useRedirectToPage();

    const selectedMedia = useMemo(() => {
        if (attachmentType === "ReceivedGiftCourse")
            return receivedCourses.find((d) => d.sku_id === mediaLinkId);

        if (attachmentType === "Course")
            return courses.find((d) => d.sku_id === mediaLinkId);

        return filteredMedia.find((d) => d.sku_id === mediaLinkId);
    }, [filteredMedia, mediaLinkId, attachmentType, receivedCourses, courses]);

    useEffect(() => {
        if (isSameUser && selectedMedia) {
            redirectToDesiredPage(attachmentType, selectedMedia.nid);
        }
    }, [attachmentType, selectedMedia, isSameUser]);

    return (
        <Layout>
            <Box className={clsx(className, classes.root)} {...rest}>
                {!isSameUser && (
                    <AppFallBackComponent
                        desc={`Please login with IBO No ${memberId} to view this content.`}
                        routeText="Go To Homepage"
                        route={linkProvider.react.home()}
                    />
                )}

                {isSameUser && loading && (
                    <AppBackdropProgress
                        open={loading}
                        backdropText={"Redirecting"}
                    />
                )}

                {isSameUser && !loading && !selectedMedia && (
                    <AppFallBackComponent
                        desc={`Sku ${mediaLinkId} is unavailable`}
                        routeText="Go To Homepage"
                        route={linkProvider.react.home()}
                    />
                )}
            </Box>
        </Layout>
    );
};

const useRedirectToPage = () => {
    const history = useHistory();
    const { linkProvider } = useRouting();

    const redirectToDesiredPage = (
        attachmentType: SkuAttachment,
        nid: string,
    ) => {
        if (attachmentType === "MP3") {
            history.replace(
                linkProvider.react
                    .audios()
                    .detail(nid, AudioCategoryTitle.ALL_AUDIOS),
            );
        }

        if (attachmentType === "Video") {
            history.replace(
                linkProvider.react
                    .videos()
                    .detail(nid, VideoCategoryTitle.ALL_VIDEOS, "true"),
            );
        }

        if (attachmentType === "ReceivedGiftMP3") {
            history.replace(
                linkProvider.react
                    .gifts()
                    .giftAudioDetail(
                        nid,
                        GiftCategories.RECEIVED_AS_MEMBER,
                        AllTabs.AUDIOS,
                        "false",
                    ),
            );
        }

        if (attachmentType === "ReceivedGiftVideo") {
            history.replace(
                linkProvider.react
                    .gifts()
                    .giftAudioDetail(
                        nid,
                        GiftCategories.RECEIVED_AS_MEMBER,
                        AllTabs.VIDEOS,
                        "true",
                    ),
            );
        }

        if (attachmentType === "ReceivedGiftCourse") {
            history.replace(
                linkProvider.react.courses().detail(nid, { received: "true" }),
            );
        }

        if (attachmentType === "Course") {
            history.replace(linkProvider.react.courses().detail(nid));
        }
    };

    return {
        redirectToDesiredPage,
    };
};

const useContent = (isSameUser: boolean, attachmentType: SkuAttachment) => {
    const skipAudios = attachmentType !== "MP3";
    const skipVideos = attachmentType !== "Video";
    const skipCourses = attachmentType !== "Course";
    const skipReceivedMemberAudios = attachmentType !== "ReceivedGiftMP3";
    const skipReceivedMemberVideos = attachmentType !== "ReceivedGiftVideo";
    const skipReceivedMemberCourses = attachmentType !== "ReceivedGiftCourse";

    const { loading: audiosLoading, audios } = useAudios(
        skipAudios || !isSameUser,
        false,
    );
    const { loading: videosLoading, videos } = useVideos(
        skipVideos || !isSameUser,
        false,
    );
    const {
        loading: receivedMemberAudioGiftsLoading,
        localReceivedMemberAudioGifts: receivedMemberAudioGifts,
    } = useReceivedMemberAudioGifts(skipReceivedMemberAudios || !isSameUser);
    const {
        loading: receivedMemberVideoGiftsLoading,
        localReceivedMemberVideos: receivedMemberVideoGifts,
    } = useReceivedMemberVideoGifts(skipReceivedMemberVideos || !isSameUser);
    const { loading: coursesLoading, coursesLocal: courses } = useCourses(
        !skipCourses && isSameUser,
        false,
    );
    const {
        loading: receivedMemberCourseGiftsLoading,
        receivedLocal: receivedCourses,
    } = useReceivedCourses(skipReceivedMemberCourses || !isSameUser);

    const filteredMedia: (Content | Gifts)[] = useMemo(() => {
        return [
            ...audios,
            ...videos,
            ...receivedMemberAudioGifts,
            ...receivedMemberVideoGifts,
        ];
    }, [videos, audios, receivedMemberAudioGifts, receivedMemberVideoGifts]);

    const loading =
        audiosLoading ||
        receivedMemberAudioGiftsLoading ||
        receivedMemberVideoGiftsLoading ||
        receivedMemberCourseGiftsLoading ||
        videosLoading ||
        coursesLoading;

    return {
        filteredMedia,
        receivedCourses,
        courses,
        loading,
    };
};

const useQueryParams = () => {
    const location = useLocation();

    const queryParams: AttachSkuQueryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const {
            attachment_type = "",
            media_link_id = "",
            member_id = "",
        } = parsedQueryString;

        return {
            attachment_type: attachment_type
                ? (attachment_type.toString() as SkuAttachment)
                : null,
            media_link_id: media_link_id ? media_link_id.toString() : null,
            member_id: member_id ? member_id.toString() : null,
        };
    }, [location?.search]);

    return {
        attachmentType: queryParams.attachment_type,
        mediaLinkId: queryParams.media_link_id,
        memberId: queryParams.member_id,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
    }),
);

export interface AttachedSkuProps extends InternalStandardProps<BoxProps> {}

type SkuAttachment =
    | "MP3"
    | "Video"
    | "ReceivedGiftMP3"
    | "ReceivedGiftVideo"
    | "ReceivedGiftCourse"
    | "Course"
    | "Video";
interface AttachSkuQueryParams {
    attachment_type: SkuAttachment;
    media_link_id: string;
    member_id: string;
}
