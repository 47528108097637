import { config } from "JS/Config";
import {
    AddToCart,
    AvailableCredits,
    ConfirmContentPurchase,
    ResponseMessage,
} from "JS/Models/Media";
import { BaseService } from "../BaseService";
import { appConstants } from "JS/Helpers/Contants";

export class PurchaseService extends BaseService {
    getAvailableCredits = () =>
        this.ngCall<AvailableCredits>(
            "get",
            appConstants.ngEndpoints.availableCredits(config.user.memberId),
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );

    purchaseContent = (skuId: string, quantity: number) =>
        this.ngCall<AddToCart>(
            "post",
            appConstants.ngEndpoints.purchaseContent,
            {
                memberId: config.user.memberId,
                skuId,
                quantity,
            },
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );

    redeemContent = (skuId: string) =>
        this.ngCall<ResponseMessage>(
            "post",
            appConstants.ngEndpoints.redeemContent,
            {
                memberId: config.user.memberId,
                skuId,
            },
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );

    redeemMultipleContent = (skuId: string, quantity: number) =>
        this.ngCall<ResponseMessage>(
            "post",
            appConstants.ngEndpoints.redeemMultipleContent,
            {
                memberId: config.user.memberId,
                skuId,
                quantity,
            },
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );

    confirmPurchase = (cartId: string, skuId: string, quantity: number) =>
        this.ngCall<ConfirmContentPurchase>(
            "post",
            appConstants.ngEndpoints.confirmPurchase,
            {
                memberId: config.user.memberId,
                cartId,
                skuId,
                quantity,
            },
            {
                headers: {
                    authorization: `Bearer ${config.ngAccesstoken}`,
                },
            },
        );
}
