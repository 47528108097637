import { useState, useCallback, useEffect } from "react";
import {
    Category,
    Content,
    ContentDetails,
    ContentResponse,
    Gifts,
    ResumeContent,
} from "JS/Models";
import { createOfflineIDBID, sortArrayByKey } from "JS/Helpers";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    setGlobalVideos,
    setGlobalPlayedVideos,
    setGlobalUserResumeVideos,
    setVideoDetails,
} from "JS/Redux/Video";
import { VideoService } from "JS/Services/Video";
import { setGlobalMedia } from "JS/Redux/media";
import moment from "moment";
import { config } from "JS/Config";
import { useBulkUpdateVideosDB, useVideoIndexFromDb } from "./Database/Video";
import { VideoDexie } from "JS/Database/Dexie";
import { keyBy } from "lodash";
import {
    AudioVideoResume,
    PlayedAudiosVideos,
} from "JS/Models/Firebase/Resume";
import { useGlobalMediaEssentials } from "./MediaEssentials";
import { messaging } from "JS/Helpers/UserMessaging";

export const service = new VideoService();

export const useVideos = (skip: boolean, isUpdateRequired: boolean) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [response, setResponse] = useState<ContentResponse>(null);
    const [videos, setVideos] = useState<Content[]>([]);

    const { resumeVideos, playedVideos } = useGlobalVideos();
    const playedVideosList =
        playedVideos && playedVideos[config.user.memberId]
            ? playedVideos[config.user.memberId]
            : [];

    const dispatch = useAppDispatch();
    const { syncOfflineVideos } = useSyncOfflineVideos();
    const { mediaEssentials } = useGlobalMediaEssentials();
    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);
        return service
            .getVideos()
            .then(async (response) => {
                if (!response?.status) {
                    enqueueSnackbar(messaging.common.error, {
                        variant: "error",
                    });
                } else {
                    const data = response?.data;
                    setResponse(data);
                    if (data) {
                        syncOfflineVideos(data?.content);
                        const allVideos: Content[] = data?.content?.map(
                            (item) => {
                                const playedVideos = playedVideosList.filter(
                                    (x) => x.nid === item.nid,
                                );

                                return {
                                    ...item,
                                    played: playedVideos.length
                                        ? playedVideos[0].played
                                        : "0",
                                    isReceived: false,
                                };
                            },
                        );

                        const categoryList: Category[] = data?.categories;

                        const videosList = sortArrayByKey(
                            allVideos,
                            "title",
                            "ASC",
                        );

                        setVideos(videosList);

                        dispatch(
                            setGlobalVideos({
                                videosList: videosList,
                                catagoriesList: sortArrayByKey(
                                    categoryList,
                                    "category_title",
                                    "ASC",
                                ),
                                playedVideos: playedVideos
                                    ? { ...playedVideos }
                                    : {},
                                resumeVideos: resumeVideos
                                    ? { ...resumeVideos }
                                    : {},
                            }),
                        );

                        dispatch(
                            setGlobalMedia({ lastCachedTime: moment().unix() }),
                        );

                        return {
                            allVideos,
                            categoryList,
                            status: response.status,
                        };
                    }
                }

                return {
                    allVideos: [],
                    categoryList: [],
                    status: false,
                };
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });

                return {
                    allVideos: [],
                    categoryList: [],
                    status: false,
                };
            })

            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (
            !skip ||
            (mediaEssentials?.allow_rule_engine_requests && isUpdateRequired)
        ) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        loaded,
        videos,
        response,
        loading,
    };
};

export const useVideoDetails = (nid: string, shouldFetch: boolean = true) => {
    const [loading, setLoading] = useState(false);
    const { setVideoDetail, videoDetails } = useGlobalVideos();
    const { enqueueSnackbar } = useSnackbar();

    const videoDetail = videoDetails ? videoDetails[nid] : null;

    const refetch = useCallback(() => {
        setLoading(true);
        return service
            .getVideoDetails(nid)
            .then(async (response) => {
                if (!response?.data)
                    throw Error("Failed to fetch video details");
                setVideoDetail(nid, response.data);
            })
            .catch(() => {
                if (!videoDetail)
                    enqueueSnackbar(messaging.common.error, {
                        variant: "error",
                    });
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (!shouldFetch) return;
        refetch();
    }, [nid, shouldFetch]);

    return {
        loading,
        refetch,
    };
};

export const useGlobalVideos = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.videos);
    const played = useAppSelector((state) => state.videos.playedVideos);
    const resume = useAppSelector((state) => state.videos.resumeVideos);

    const resumeUserVideos =
        resume && resume[`${config?.user?.memberId}`]
            ? resume[`${config?.user?.memberId}`]
            : [];

    const currentPlayedMap = played ? played : {};
    const resumeMap = resume ? resume : {};
    const playedUserVideos =
        played && played[`${config?.user?.memberId}`]
            ? played[`${config?.user?.memberId}`]
            : [];

    return {
        videos: allData?.videosList,
        resumeVideos: resume || {},
        resumeUserVideos: resumeUserVideos,

        categories: allData.catagoriesList,
        playedVideos: currentPlayedMap ? currentPlayedMap : {},
        playedUserVideos,
        videoDetails: allData?.videoDetails,

        setGlobalVideos: (videos: Content[]) => {
            dispatch(
                setGlobalVideos({
                    ...allData,
                    videosList: videos,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },
        setVideoDetail: (nid: string, detail: ContentDetails) => {
            const videoDetailsMap = !!allData?.videoDetails
                ? { ...allData.videoDetails }
                : {};
            videoDetailsMap[nid] = detail;
            dispatch(setVideoDetails(videoDetailsMap));
        },

        updateRecentlyPlayed: (video: Content | Gifts) => {
            const currentPlayed: (Content | Gifts | PlayedAudiosVideos)[] =
                currentPlayedMap[config.user.memberId]
                    ? currentPlayedMap[config.user.memberId]
                    : [];
            const newMap = { ...currentPlayedMap };
            const newPlayed = [...currentPlayed].filter(
                (c) => c.nid !== video.nid,
            );
            newPlayed.push(video);
            newMap[config.user.memberId] = newPlayed;
            dispatch(setGlobalPlayedVideos(newMap));
        },
        updateResumeVideos: (resumeVideo: ResumeContent) => {
            const currentUserResume: ResumeContent[] = resumeMap[
                config.user.memberId
            ]
                ? resumeMap[config.user.memberId]
                : [];
            const newMap = { ...resumeMap };
            const newResume = [...currentUserResume].filter(
                (c) => c.nid !== resumeVideo.nid,
            );
            newResume.push(resumeVideo);
            newMap[config.user.memberId] = newResume;
            dispatch(setGlobalUserResumeVideos(newMap));
        },
        isVideoUpdateRequired: allData?.isUpdateRequired,
        syncResumeVideos: (resumeAudios: AudioVideoResume[]) => {
            dispatch(
                setGlobalUserResumeVideos({
                    [config.user.memberId]: resumeAudios,
                }),
            );
        },
        syncPlayedVideos: (playedAudios: PlayedAudiosVideos[]) => {
            dispatch(
                setGlobalPlayedVideos({
                    [config.user.memberId]: playedAudios,
                }),
            );
        },
        getVideoDetails: (nid: string) => {
            const details = allData?.videoDetails;
            return !!details ? details[nid] : undefined;
        },
    };
};

export const useGlobalVideoDetails = (nid: string) => {
    const { videoDetails } = useGlobalVideos();

    return {
        videoDetails: !!videoDetails ? videoDetails[nid] : undefined,
    };
};

export const useSyncOfflineVideos = () => {
    const { refetch: refetchOfflineVideos } = useVideoIndexFromDb(true);
    const { bulkUpdateVideos } = useBulkUpdateVideosDB();

    const syncOfflineVideos = useCallback(
        async (videos: (Gifts | Content)[]) => {
            const offlineVideos = await refetchOfflineVideos();

            if (offlineVideos && offlineVideos.length) {
                const allIds = offlineVideos.map((d) => d.id);

                const filteredVideos = videos.filter((x) =>
                    allIds.includes(
                        createOfflineIDBID(config.user.memberId, x.nid),
                    ),
                );

                const offlineVidMap = keyBy(offlineVideos, (x) => x.id);
                const newOfflineVideos: VideoDexie[] = filteredVideos.map(
                    (v) => {
                        const currVid =
                            offlineVidMap[
                                createOfflineIDBID(config.user.memberId, v.nid)
                            ];
                        return {
                            ...currVid,
                            name: v.title,
                        };
                    },
                );

                await bulkUpdateVideos(newOfflineVideos);
            }
        },
        [refetchOfflineVideos, bulkUpdateVideos],
    );

    return {
        syncOfflineVideos,
    };
};
